import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User, VerifyEmailResponse, RegisterUserResponse, UserLoggedin, UserNavigation } from "src/types";
import { getAuthToken } from "../service/auth";

const hostUrl = process.env.REACT_APP_API_URL + '/v1';

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({ baseUrl: hostUrl }),
    endpoints: (builder) => ({
        guestCarousel: builder.mutation<any, Partial<any>>({
            query: () => ({
                url: "/carousel",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        userNavigation: builder.mutation<UserNavigation, Partial<any>>({
            query: () => ({
                url: "/navigation?filter=%7B%22deviceTypeId%22:1,%22languageId%22:1%7D",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        footerlinks: builder.mutation<UserNavigation, Partial<any>>({
            query: () => ({
                url: "/footerlink",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        footerlinksById: builder.mutation<UserNavigation, Partial<any>>({
            query: ({ linkId }) => ({
                url: `/footerlink/${linkId}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        upComingEvents: builder.mutation<UserNavigation, Partial<any>>({
            query: () => ({
                url: 'pagecategory/listing?start=0&limit=3&filter=%7B"deviceTypeId"%3A"1"%2C"langId"%3A1%2C"path"%3A1%7D',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        asset: builder.mutation<UserNavigation, Partial<any>>({
            query: ({ assetId }) => ({
                url: `asset/${assetId}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),

        cast: builder.mutation<UserNavigation, Partial<any>>({
            query: () => ({
                url: `cast?filter=%7B%22assetId%22:1%7D`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        profile: builder.mutation<UserNavigation, Partial<any>>({ 
            query: ({ UserID }) => ({
                url: `profile/${UserID}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        createOrder: builder.mutation<void, Partial<any>>({
            query: ({ orderDetails }) => ({
                url: "/order",
                method: "POST",
                body: orderDetails,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        recordedEvents: builder.mutation<UserNavigation, Partial<any>>({
            query: () => ({
                url: 'pagecategory/listing?start=0&limit=3&filter=%7B"deviceTypeId"%3A"1"%2C"langId"%3A1%2C"path"%3A2%7D',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        ticketPurchaseValidity: builder.mutation<UserNavigation, Partial<any>>({
            query: ({ userId, assetId }) => ({
                url: `checkplanvalidity?filter=%7B"userId"%3A"${userId}"%2C"assetId"%3A${assetId}%7D`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }),
        updateProfile: builder.mutation<void, Partial<any>>({
            query: (body) => ({
                url: "/profile/update",
                method: "POST",
                body: body,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getAuthToken()}`,
                },
            }),
        }), 
    }),
});

export const { useGuestCarouselMutation, useUserNavigationMutation,
    useFooterlinksMutation, useUpComingEventsMutation,
    useAssetMutation, useCastMutation, useProfileMutation,
    useFooterlinksByIdMutation, useCreateOrderMutation,
    useRecordedEventsMutation, useTicketPurchaseValidityMutation,
    useUpdateProfileMutation,
} = usersApi;
