import "./cast-card.scss";


const CastCard = ({ member }: any) => {
  return (
    <div className="circle-card-wrapper">
      <div className="Circle-card">
        {member?.horizontalFilePath && (
          <img src={member?.horizontalFilePath} alt="circle-card" />
        )}
      </div>
      <div className="Circle-card-title">
        {member?.firstName} {member?.lastName}
      </div>
      
    </div>
  );
};
export default CastCard;
