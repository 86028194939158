import { useEffect, useState } from "react";
import {
  useAssetMutation,
  useCastMutation,
  useTicketPurchaseValidityMutation,
} from "src/redux/api/usersAPI";
import { useDispatch, useSelector } from "react-redux";
import decodeJwtToken from "src/utils/decode-jwt-token";
import {
  setCastList,
  setAssetDetails,
  setPreviousRoute,
} from "src/redux/reducer/authSlice";
import { RootState } from "src/redux/store/store";
import "./event-details.scss";
import CastCard from "src/components/cast-card/cast-card";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import formatDate from "src/utils/format-date";
import { getAuthToken, getGuestUser } from "src/redux/service/auth";
import { useNavigate } from "react-router-dom";
import TimerCounter from "../timer-counter/timer-counter";
import Loader from "src/components/loader/loader";
import { SHOW_TYPE } from "src/config/config";
import "swiper/css";
import "swiper/css/navigation";
import "./event-details.scss";
import CheckoutContainer from "src/components/checkout-container/checkout-container";
import analytics from 'src/config/firebase-config'; 
import { logEvent } from "firebase/analytics";

const EventDetails = () => {
  const [asset] = useAssetMutation();
  const [cast] = useCastMutation();
  const [ticketPurchaseValidity] = useTicketPurchaseValidityMutation();
  const dispatch = useDispatch();
  const { castList, assetDetails } = useSelector(
    (state: RootState) => state.auth
  );
  const path = window.location.pathname;
  const id = path.split("/");
  const data: any = [];
  const navigate = useNavigate();
  const isGuest = getGuestUser();
  const [currentAsset, setCurrentAsset] = useState(data);
  const [userOrderDetails, setUserOrderDetails] = useState();
  const [ticketPurchased, setTicketPurchased] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const currentAssetRef = assetDetails?.find(
      (asset: any) => asset.path == id[2]
    );
    if (!assetDetails || assetDetails.length === 0 || !currentAssetRef) {
      getAssetDetails();
    } else {
      setCurrentAsset(currentAssetRef);
      getUserToken(currentAssetRef);
    }
    getCastList();

    if (isGuest !== "true") {
      checkUserTicketValidity();
    }
  }, []);

  const getAssetDetails = async () => {
    setShowLoader(true);
    const response: any = await asset({ assetId: id[2] });
    const decodedToken = decodeJwtToken(response?.data);
    const { data } = decodedToken;
    dispatch(setAssetDetails(data[0]));
    setCurrentAsset(data[0]);
    getUserToken(data[0]);
    setShowLoader(false);
  };

  const getCastList = async () => {
    const response: any = await cast({});
    const decodedToken = decodeJwtToken(response?.data);
    const { data } = decodedToken;
    dispatch(setCastList(data));
  };

  const checkUserTicketValidity = async () => {
    const response: any = await ticketPurchaseValidity({
      userId: getUserId(),
      assetId: id[2],
    });
    const decodedToken = decodeJwtToken(response?.data);
    const { data } = decodedToken;
    setTicketPurchased(data.isActivePlan);
  };

  const getUserToken = (assetDetail: any) => {
    const token: any = getAuthToken();
    const decodedToken: any = decodeJwtToken(token);
    const { data } = decodedToken;
    const { name, email, userId } = data;
    const userOrderDetails: any = {
      path: id[2],
      subscriptionId: assetDetail?.subscription_id,
      billingName: name,
      billingEmail: email,
      userId: userId,
      rate: assetDetail?.rate,
    };
    setUserOrderDetails(userOrderDetails);
  };

  const getUserId = () => {
    const token: any = getAuthToken();
    const decodedToken: any = decodeJwtToken(token);
    const { data } = decodedToken;
    return data?.userId ? data.userId : "";
  };

  const handleBuyTicket = () => {
    dispatch(setPreviousRoute(path));
    navigate(`/sign-in`);
  };

  const handleJoinCrowd = (currentAssetDetails: any) => {
    return (
      currentAssetDetails?.airStartDate &&
      new Date() < new Date(currentAssetDetails?.airStartDate) &&
      currentAssetDetails?.contentType === SHOW_TYPE.LIVE &&
      ticketPurchased
    );
  };

  const handleLiveNow = (currentAssetDetails: any) => {
    return (
      currentAssetDetails?.airStartDate &&
      new Date() >= new Date(currentAssetDetails?.airStartDate) &&
      currentAssetDetails?.contentType === SHOW_TYPE.LIVE &&
      ticketPurchased
    );
  };

  const handleWatchNow = (currentAssetDetails: any) => {
    return (
      ticketPurchased &&
      currentAssetDetails?.airStartDate &&
      new Date() > new Date(currentAssetDetails?.airStartDate) &&
      currentAssetDetails?.contentType === SHOW_TYPE.VOD
    );
  };

  useEffect(() => {
    logEvent(analytics, 'FireBase event detail page called');
  }, []);

  return (
    <div className="event-container-wrapper">
      <div className="heroContainer">
        <Swiper cssMode={true} className="heroSwiper">
          {currentAsset && !showLoader ? (
            <SwiperSlide key={1} className="heroSwiperSlide">
              <div className="imgCarousel">
                <img
                  className="swiper-img"
                  src={currentAsset?.vodOrLivePosterImageFilePath}
                />
                <div className="info-event-wrapper">
                  <div className="event-direction">
                    <div className="new-timer">
                      {currentAsset.contentType === SHOW_TYPE.LIVE &&
                        currentAsset?.airStartDate && (
                          <TimerCounter
                            startDate={currentAsset?.airStartDate}
                          ></TimerCounter>
                        )}
                    </div>
                    {handleLiveNow(currentAsset) && (
                      <div className="text-live">
                        Live Now <span className="line-doat"></span>
                      </div>
                    )}
                  </div>

                  <div className="info-event-main-heading">
                    {currentAsset?.TitleLogoPath}
                  </div>
                  <div className="info-event-heading">
                    {currentAsset?.label}
                  </div>
                  <div className="info-event-title">
                    {currentAsset?.event_label}
                  </div>
                  <div className="info-event-sub-heading">
                    {currentAsset?.venue_description}
                  </div>
                  <div className="info-event-details">
                    {currentAsset?.airStartDate &&
                      formatDate(currentAsset?.airStartDate)}
                  </div>
                  <div className="btn-event-wrapper">
                    {isGuest === "true" && (
                      <button
                        className="btn-event-buy"
                        onClick={handleBuyTicket}
                      >
                        Buy tickets
                      </button>
                    )}

                    {!ticketPurchased && isGuest === "false" && (
                      <CheckoutContainer
                        userOrderDetails={userOrderDetails}
                        onCheckoutLoader={setShowLoader}
                      />
                    )}

                    {isGuest !== "true" && handleJoinCrowd(currentAsset) && (
                      <button
                        className="btn-event-buy"
                        onClick={() => navigate(`/video/${id[2]}`)}
                      >
                        Join Crowd
                      </button>
                    )}

                    {isGuest !== "true" && handleLiveNow(currentAsset) && (
                      <button
                        className="btn-event-buy"
                        onClick={() => navigate(`/video/${id[2]}`)}
                      >
                        Live Now
                      </button>
                    )}

                    {isGuest !== "true" && handleWatchNow(currentAsset) && (
                      <button
                        className="btn-event-buy"
                        onClick={() => navigate(`/video/${id[2]}`)}
                      >
                        Watch Now
                      </button>
                    )}

                    <button
                      className="btn-event-upcomig"
                      onClick={() => navigate(`/upcoming-event`)}
                    >
                      Shows
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ) : (
            <Loader />
          )}
        </Swiper>
      </div>

      <div className="event-details">
        <div className="event-description">
          <div
            dangerouslySetInnerHTML={{ __html: currentAsset?.description }}
          />
        </div>
      </div>
      <div className="line-wrapper"></div>
      <div className="casting-wrapper">
        <div className="contentrail-wrapper">
          <div className="rail-title">LineUp</div>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            slidesPerView="auto"
            className="ml-25"
          >
            {castList?.data?.map((member: any) => {
              return (
                <SwiperSlide key={member?.firstName}>
                  <CastCard member={member} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
export default EventDetails;
