import React, { useEffect, useRef, useState } from 'react';
import { AppConstants, EventConstants, PUBNUB_SIGNALS } from '../../constants';
import eventEmitter from '../../utils/VoltaEventEmitter';
import { ReactComponent as INFullscreen } from '../../../../assets/icons/close_fullscreen.svg';
import { ReactComponent as Fullscreen } from '../../../../assets/icons/Icons-11.svg';
import { ReactComponent as Box } from '../../../../assets/icons/Icons-23.svg';
import { ReactComponent as QualityIcon } from '../../../../assets/icons/podcastsQuality.svg';

interface BottomRightProps {
  data: { meta_key: string; meta_value: string }[];
  setData: React.Dispatch<
    React.SetStateAction<{ meta_key: string; meta_value: string }[]>
  >;
  liveVideoUrls: { meta_key: string; meta_value: string }[];
  isFullScreen: boolean;
  gridValue: string;
  setGridValue: React.Dispatch<React.SetStateAction<string>>;
  toggleFullScreen: () => void;
  isStreamLive: boolean;
  isMutliView: number;
  signal: string | null;
}

const BottomRight: React.FC<BottomRightProps> = ({
  data,
  setData,
  liveVideoUrls,
  isFullScreen,
  gridValue,
  setGridValue,
  toggleFullScreen,
  isStreamLive,
  isMutliView,
  signal,
}) => {
  // States
  const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false);
  const [isQualityDropDownVisible, setIsQualityDropDownVisible] =
    useState<boolean>(false);
  const [qualityLevels, setQualityLevels] = useState<string[]>([]);
  const [selectedQuality, setSelectedQuality] = useState('Auto');

  const toggleGrid = (event: string) => {
    switch (event) {
      case AppConstants.SOLO_VIDEO_VIEW_STREAM_TITLE:
        setData(data);
        break;
      case AppConstants.PLAYER_MODE_THREE_BY_THREE_TITLE:
        setData(liveVideoUrls);
        break;
      case AppConstants.TWO_TILTED_VIEW_TITLE:
        setData(liveVideoUrls.slice(0, 2));
        break;
      case AppConstants.CORNERS_VIEW_TITLE:
      case AppConstants.FEATURE_VIEW_TITLE:
      case AppConstants.PLAYER_MODE_TWO_BY_TWO_TITLE:
        setData(liveVideoUrls.slice(0, 4));
        break;
      default:
        setData(data);
        break;
    }
    setGridValue(event);
    setIsDropDownVisible(false);
  };

  const toggleViewModeDropDown = () => {
    if (isStreamLive && isMutliView === 1) {
      setIsDropDownVisible(!isDropDownVisible);
    }
  };

  const changeQuality = (selectedOption: {
    bitrate: number;
    qualityLabel: string;
  }) => {
    setSelectedQuality(selectedOption.qualityLabel);
    eventEmitter.emit(EventConstants.CHANGEQUALITY, {
      selectedOption,
    });
    setIsQualityDropDownVisible(false);
  };

  const getQualityLevels = (data: any) => {
    setQualityLevels(data.qualityLevels);
  };



      const initializeAirPlay = () => {
        eventEmitter.emit(EventConstants.AIRPLAY,'');
      }

  useEffect(() => {
    console.log("liveVideoUrls",data)
    console.log("liveVideoUrls",isStreamLive)
    console.log("liveVideoUrls",liveVideoUrls)

    eventEmitter.on(EventConstants.GETQUALITYLEVEL, getQualityLevels);

    return () => {
      eventEmitter.on(EventConstants.GETQUALITYLEVEL, getQualityLevels);
    };
  }, []);

  return (
    <ul className="bottomRight">
      <li>
        <img
          alt="video_tab"
          src="/images/icons/Icons-16.png"
          className="horiMenuImg"
          id="castBtn"
          onClick={initializeAirPlay}
        ></img>

      </li>

      {/* <li className="activePlayerViewTitle">{gridValue}</li> */}

      {/* <li>
        <span className="verticalLine"></span>
      </li> */}

      <li className='quality-icon'>
        {gridValue === AppConstants.PLAYER_MODE_SOLO_TITLE &&
        qualityLevels.length >= 0 ? (
          <ul className="qualityContainer">
            {isQualityDropDownVisible && (
              <div className="qualityDropDownContainer">
                {qualityLevels.map((level: any, index: number) => (
                  <li
                    key={index}
                    className="qualityDropDownItem"
                    onClick={() => changeQuality(level)}
                  >
                    <span
                      className={
                        selectedQuality === level.qualityLabel
                          ? 'activeQuality'
                          : ''
                      }
                    >
                      {level.qualityLabel}
                    </span>
                  </li>
                ))}
              </div>
            )}
            <QualityIcon
              className="quality-icon"
              onClick={() =>
                setIsQualityDropDownVisible(!isQualityDropDownVisible)
              }
            />
          </ul>
        ) : (
          <></>
        )}
      </li>



      {isStreamLive &&
        isMutliView !== 0 &&
        signal === PUBNUB_SIGNALS.STREAM_START && (
          <li className="playerViewModeContainer">
            {gridValue === AppConstants.PLAYER_MODE_SOLO_TITLE ? (
              <Box className="player-icon" onClick={toggleViewModeDropDown} />
            ) : gridValue === AppConstants.PLAYER_MODE_THREE_BY_THREE_TITLE ? (
              <img
                alt="video_tab"
                src="/images/icons/Icons-21.png"
                onClick={toggleViewModeDropDown}
              ></img>
            ) : gridValue === AppConstants.PLAYER_MODE_TWO_BY_TWO_TITLE ? (
              <img
                alt="video_tab"
                src="/images/icons/Icons-23.png"
                onClick={toggleViewModeDropDown}
              ></img>
            ) : gridValue === AppConstants.TWO_TILTED_VIEW_TITLE ? (
              <img
                alt="video_tab"
                src="/images/icons/Icons-24.png"
                onClick={toggleViewModeDropDown}
              ></img>
            ) : gridValue === AppConstants.CORNERS_VIEW_TITLE ? (
              <img
                alt="video_tab"
                src="/images/icons/Icons-25.png"
                onClick={toggleViewModeDropDown}
              ></img>
            ) : gridValue === AppConstants.FEATURE_VIEW_TITLE ? (
              <img
                alt="video_tab"
                src="/images/icons/Icons-26.png"
                onClick={toggleViewModeDropDown}
              ></img>
            ) : null}
            {isDropDownVisible && (
              <div className="dropdown">
                <ul className="dropdownMenu">
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(AppConstants.PLAYER_MODE_SOLO_TITLE);
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.PLAYER_MODE_SOLO_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-22.png"
                      ></img>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(
                          AppConstants.PLAYER_MODE_THREE_BY_THREE_TITLE
                        );
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.PLAYER_MODE_THREE_BY_THREE_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-21.png"
                      ></img>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(AppConstants.PLAYER_MODE_TWO_BY_TWO_TITLE);
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.PLAYER_MODE_TWO_BY_TWO_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-23.png"
                      ></img>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(AppConstants.TWO_TILTED_VIEW_TITLE);
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.TWO_TILTED_VIEW_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-24.png"
                      ></img>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(AppConstants.CORNERS_VIEW_TITLE);
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.CORNERS_VIEW_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-25.png"
                      ></img>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdownItem"
                      onClick={() => {
                        toggleGrid(AppConstants.FEATURE_VIEW_TITLE);
                      }}
                    >
                      <div className="view-name">
                        {AppConstants.FEATURE_VIEW_TITLE}
                      </div>
                      <img
                        alt="video_tab"
                        src="/images/icons/Icons-26.png"
                      ></img>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </li>
        )}

      <li>
        {isFullScreen ? (
          <INFullscreen
            className="player-icon fullicon"
            onClick={toggleFullScreen}
          />
        ) : (
          <Fullscreen className="player-icon" onClick={toggleFullScreen} />
        )}
      </li>
    </ul>
  );
};

export default BottomRight;
