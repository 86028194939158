import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSliderList,
  setUserInfo
} from 'src/redux/reducer/authSlice';
import { RootState } from 'src/redux/store/store';
import Carousel from 'src/components/carousel/carousel';
import decodeJwtToken from 'src/utils/decode-jwt-token';
import Vlogo from 'src/assets/V-logo.svg';
import Group from 'src/assets/Group 349.png';
import { useNavigate } from 'react-router-dom';
import {
  useProfileMutation,
  useGuestCarouselMutation
} from 'src/redux/api/usersAPI';
import { getAuthToken, getLoggedInUserInfo, getuseridToken } from 'src/redux/service/auth';
import { getGuestUser } from 'src/redux/service/auth';
import './home.scss';
import Loader from 'src/components/loader/loader';
import { scrollToTop } from 'src/utils/srcoll-to-top';
import analytics from 'src/config/firebase-config';
import { logEvent } from 'firebase/analytics';
import HomeBanner from 'src/components/homeBanner/homeBanner';
import HomeBottomBanner from 'src/components/homeBottomBanner/homeBottomBanner';
import CountdownTimer from 'src/utils/timer';

const Home = () => {
  const [guestCarousel] = useGuestCarouselMutation();
  const [profile] = useProfileMutation();
  const { sliderList, userInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isGuest = getGuestUser();
  const [showCountdown, setShowCountdown] = useState(true);

  useEffect(() => {
    scrollToTop();
    const token = getAuthToken();
    async function getCarouselList() {
      const response: any = await guestCarousel({});
      const decodedToken = decodeJwtToken(response?.data?.toString());
      const { data } = decodedToken;
      dispatch(setSliderList(data));
    }
    if (sliderList?.length === 0) getCarouselList();
    onSetUserInfo();
  }, [dispatch, guestCarousel, sliderList]);

  const onSetUserInfo = () => {
    const userData = getLoggedInUserInfo();
    const UserID = getuseridToken();
    if (userData && userData.userId) {
      userData.userId = UserID;
    }
    dispatch(setUserInfo(userData));
  };

  useEffect(() => {
    logEvent(analytics, 'FireBase home page called');
  }, []);

  const handleTimerEnd = () => {
    setShowCountdown(false);
  };

  return (
    <div className='Main-conatiner'>
      {sliderList?.length > 0 ? (
        <>
          <HomeBanner sliderList={sliderList}  />
          <Carousel sliderList={sliderList} />
          <div className="landing-info-wrapper">
            {/* {showCountdown && (
              <div className="info-wrapper-heading">
                THE SHOW STARTS IN
                <br></br>
                 <CountdownTimer onEnd={handleTimerEnd} />
              </div>
            )} */}
          </div>
          <HomeBottomBanner />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Home;
