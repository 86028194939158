import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User, VerifyEmailResponse, RegisterUserResponse, UserLoggedin, UserNavigation } from "src/types";
import { getAuthToken } from "../service/auth";

const hostUrl = process.env.REACT_APP_API_URL + '/v1';

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({ baseUrl: hostUrl }),
	endpoints: (builder) => ({
		verifyEmail: builder.mutation<VerifyEmailResponse, Partial<any>>({
			query: (email) => ({
				url: "/checkuser",
				method: "POST",
				body: email,
			}),
		}),
		sendOTP: builder.mutation<void, Partial<any>>({
			query: (email) => ({
				url: "/sendotp",
				method: "POST",
				body: email,
			}),
		}),
		verifyOTP: builder.mutation<void, Partial<any>>({
			query: ({ emailId, otp }) => ({
				url: "/verifyotp",
				method: "POST",
				body: { emailId, otp },
			}),
		}),
		registerUser: builder.mutation<RegisterUserResponse, Partial<any>>({
			query: (user) => ({
				url: "/register",
				method: "POST",
				body: user,
			}),
		}),
		guestUser: builder.mutation<any, Partial<any>>({
			query: () => ({
				url: "/token/guest",
				method: "GET",
			}),
		}),
		userLogin: builder.mutation<UserLoggedin, Partial<any>>({
			query: (user) => ({
				url: "/login",
				method: "POST",
				body: user,
			}),
		}),
		fbLogin: builder.mutation<void, Partial<any>>({
			query: (user) => ({
				url: "/login",
				method: "POST",
				body: user,
			}),
		}),
		googleLogin: builder.mutation<void, Partial<any>>({
			query: (user) => ({
				url: "/login",
				method: "POST",
				body: user,
			}),
		}),
		fbRegister: builder.mutation<void, Partial<any>>({
			query: (user) => ({
				url: "/register",
				method: "POST",
				body: user,
			}),
		}),
		googleRegister: builder.mutation<void, Partial<any>>({
			query: (user) => ({
				url: "/register",
				method: "POST",
				body: user,
			}),
		}),
	}),
});

export const { useVerifyEmailMutation, useSendOTPMutation,
	useVerifyOTPMutation, useRegisterUserMutation,
	useUserLoginMutation, useGuestUserMutation,
	useFbLoginMutation, useGoogleLoginMutation,
	useFbRegisterMutation, useGoogleRegisterMutation
} = authApi;
