import React, { useEffect } from "react";
import Layout from "src/layout/layout-container";
import { useDispatch, useSelector } from "react-redux";
import { useGuestUserMutation } from "src/redux/api/authAPI";
import { useUserNavigationMutation } from "src/redux/api/usersAPI";
import { getAuthToken, setAuthToken, setGuestUser } from "src/redux/service/auth";
import decodeJwtToken from "src/utils/decode-jwt-token";
import { setUserNavigation } from "./redux/reducer/authSlice";
import "./style.scss";
import "./App.scss";


function App() {
  const [guestUser] = useGuestUserMutation();
  const [userNavigation] = useUserNavigationMutation();
  const dispatch = useDispatch();
  const isBootstrapApp = getAuthToken();

  useEffect(() => {
    async function getGuestToken() {
      const guestToken: any = await guestUser({});
      const { data } = guestToken;
      setAuthToken(data);
      setGuestUser(true);
    }
    if (!getAuthToken()) getGuestToken();
  }, [])


  useEffect(() => {
    if (isBootstrapApp) getNavigation();
  }, [isBootstrapApp]);


  const getNavigation = async () => {
    const navList: any = await userNavigation({}); // navigation for logo's
    const decodeToken = decodeJwtToken(navList?.data)
    if (decodeToken.success) {
      dispatch(setUserNavigation(decodeToken));
    }
  }

  return (
    <>
      {isBootstrapApp && <Layout />}
    </>
  );
}

export default App;
