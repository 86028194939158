import moment from 'moment';

export const FormatDuration = (duration: number) => {
  const durationObject = moment.duration(duration, 'seconds');
  const hours = Math.floor(durationObject.asHours());
  const minutes = durationObject.minutes();
  const seconds = durationObject.seconds();

  const strHours = hours === 0 ? '' : `${hours}:`;
  const strMinutes = minutes < 10 ? `0${minutes}:` : `${minutes}:`;
  const strSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${strHours}${strMinutes}${strSeconds}`;
};
