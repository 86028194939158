import React from 'react';
import eventEmitter from '../utils/VoltaEventEmitter';
import { EventConstants } from '../constants';

interface ProgressBarProps {
  totalDuration: number;
  isStreamLive: boolean;
}
const ProgressBar = React.forwardRef<HTMLInputElement, ProgressBarProps>(
  ({ totalDuration, isStreamLive }, ref) => {
    const seeking = (time: any) => {
      eventEmitter.emit(EventConstants.SEEKING, {
        seekingValue: time,
      });
    };

    return (
      <div className="progressBarContainer">
        <input
          disabled={isStreamLive}
          ref={ref}
          defaultValue={0}
          className="progressSlider"
          id="volume"
          type="range"
          min={0}
          max={isNaN(totalDuration) ? undefined : totalDuration}
          step={'0.01'}
          onChange={(e) => {
            const time = parseFloat(e.target.value);
            seeking(time);
          }}
        />
        {/* <img
          alt="temp"
          src="/images/horizontal_bar.png"
          width="100%"
          className="progressImage"
        ></img> */}
      </div>
    );
  }
);

export default ProgressBar;
