
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authReducer from "src/redux/reducer/authSlice";
import { authApi } from "src/redux/api/authAPI";
import { usersApi } from "src/redux/api/usersAPI";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: authReducer,
		[authApi.reducerPath]: authApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware, usersApi.middleware),	
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;

export default store;
