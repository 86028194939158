import { useCallback, useState } from "react";
import { FacebookLoginButton, } from "react-social-login-buttons";
import { LOGIN_SOURCE, SSO_PROVIDER, FACEBOOK_APP_ID } from "src/config/config";
import { useFbRegisterMutation, useGoogleLoginMutation, useGoogleRegisterMutation, useVerifyEmailMutation } from "src/redux/api/authAPI";
import decodeJwtToken from "src/utils/decode-jwt-token";
import { setAuthToken, setGuestUser, setUsertokenId } from "src/redux/service/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/loader";
import facbookIcon from 'src/assets/facebook-social-icon.png';
import googleIcon from 'src/assets/google-social-icon.png';
import analytics from 'src/config/firebase-config';
import { logEvent } from "firebase/analytics";
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import './social-login.scss';

const isInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return (
        userAgent.includes("Instagram") ||
        userAgent.includes("FBAN") || // Facebook
        userAgent.includes("FBAV") || // Facebook
        userAgent.includes("Twitter") ||
        userAgent.includes("Snapchat")
    );
};

const redirectToDefaultBrowser = (url: string) => {
    const chromeUrl = `googlechrome://${url}`;
    const firefoxUrl = `firefox://open-url?url=${url}`;
    const safariUrl = url; // Safari should handle http/https directly

    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        alert("For a better experience, please open this link in your default browser using Google Sign-In.");
    } else if (navigator.userAgent.match(/Android/i)) {
        alert("For a better experience, please open this link in your default browser using Google Sign-In.");
    } else {
        alert("For a better experience, please open this link in your default browser using Google Sign-In.");
    }

    // setTimeout(() => {
    //     window.location.href = url; // Fallback to original URL if redirection fails
    // }, 500);
};

const SocialLogin = () => {
    const [loading, setLoading] = useState(false);
    const [googleLogin] = useGoogleLoginMutation();
    const [fbLogin] = useGoogleLoginMutation();
    const [googleRegister] = useGoogleRegisterMutation();
    const [fbRegister] = useFbRegisterMutation();
    const [verifyEmail] = useVerifyEmailMutation();
    const navigate = useNavigate();

    const handleSocialLogin = async (provider: any, data: any) => {
        setLoading(true);
        const { access_token, email } = data;
        const response: any = await verifyEmail({ emailId: email, loginSource: LOGIN_SOURCE.SOURCE_3 });
        const decodeToken = decodeJwtToken(response.data);
        if (decodeToken.success) {
            let resp: any;
            switch (provider) {
                case SSO_PROVIDER.GOOGLE:
                    resp = await googleLogin({ emailId: email, loginSource: LOGIN_SOURCE.SOURCE_1, accessToken: access_token });
                    break;
                case SSO_PROVIDER.FACEBOOK:
                    resp = await fbLogin({ emailId: email, provider, accessToken: data.accessToken, loginSource: LOGIN_SOURCE.SOURCE_2 });
                    break;
                default:
                    console.log('Unsupported provider');
                    setLoading(false);
                    return;
            }
            const decodedToken = decodeJwtToken(resp.data);
            if (decodedToken.success) {
                logEvent(analytics, `${provider} user login details FROM sso login`,
                    {
                        user_name: `${decodedToken?.data[0]?.firstName} ${decodedToken?.data[0]?.lastName}`,
                        user_email: decodedToken.data[0]?.emailId,
                        user_id: decodedToken?.data[0]?.id,
                        user_login_Type: `${provider}`
                    }
                );
                setUsertokenId(decodedToken?.data[0]?.token);
                setGuestUser(false);
                setLoading(false);
                navigate(`/home`);
            } else {
                console.log('Error in SSO login:', decodedToken);
                setLoading(false);
            }
        } else {
            registerUser(provider, data);
        }
    };

    const registerUser = async (provider: any, data: any) => {
        setLoading(true);
        const { access_token, email, given_name, family_name, name, accessToken } = data;
        let loginSource;
        if (provider === SSO_PROVIDER.GOOGLE) {
            loginSource = LOGIN_SOURCE.SOURCE_1;
        } else if (provider === SSO_PROVIDER.FACEBOOK) {
            loginSource = LOGIN_SOURCE.SOURCE_2;
        }
        if (!loginSource) {
            console.log('Invalid provider');
            setLoading(false);
            return;
        }
        try {
            const resp: any = await (provider === SSO_PROVIDER.GOOGLE ?
                googleRegister({
                    accessToken: access_token,
                    deviceType: 1,
                    emailId: email,
                    firstName: given_name,
                    lastName: family_name,
                    loginSource: loginSource,
                }) :
                fbRegister({
                    accessToken: accessToken,
                    deviceType: 1,
                    emailId: email,
                    firstName: name,
                    loginSource: loginSource,
                })
            );

            if (!resp.data) {
                console.log(`${provider}, client id mismatch`);
                setLoading(false);
                return;
            }

            const decodedToken: any = decodeJwtToken(resp?.data);
            if (decodedToken.success) {
                logEvent(analytics, `${provider} user registration details FROM sso login`,
                    {
                        user_name: `${decodedToken?.data[0]?.firstName} ${decodedToken?.data[0]?.lastName}`,
                        user_email: decodedToken.data[0]?.emailId,
                        user_id: decodedToken?.data[0]?.id,
                        user_login_Type: `${provider}`
                    }
                );
                setUsertokenId(decodedToken?.data[0]?.token);
                setGuestUser(false);
                setLoading(false);
                navigate(`/home`);
            } else {
                console.log(decodedToken.message);
            }
        } catch (error) {
            console.error('Error registering user:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }

    };

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            fetchUserProfile(codeResponse);
        },
        flow: 'implicit',
    });

    const fetchUserProfile = async ({ access_token }: { access_token: string }) => {
        try {
            setLoading(true);
            const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
                headers: { Authorization: `Bearer ${access_token}` },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user profile');
            }

            const userData = await response.json();
            const { email, given_name, family_name } = userData;
            const data = {
                access_token,
                email,
                given_name,
                family_name
            };

            handleSocialLogin('google', data);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching user profile:', error);
        }
    };

    const responseFacebook = (response: any) => {
        console.log(response); 
        if (response.status !== "unknown") {
            let { accessToken, email, name } = response;
            const data = {
                accessToken,
                email,
                name
            };

            handleSocialLogin('facebook', data);
        }
        // Handle the response from Facebook here
    };

    const handleGoogleLoginClick = () => {
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=943262573218-q1vku4jfka5pqnn8em2b0b2ojgfjg2kb.apps.googleusercontent.com&scope=openid%20profile%20email&redirect_uri=storagerelay%3A%2F%2Fhttp%2Flocalhost%3A3000%3Fid%3Dauth738024&prompt=select_account&response_type=token&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`
        if (isInAppBrowser()) {
            redirectToDefaultBrowser(authUrl);
        } else {
            login();
        }
    };

    return (
        <div>
            {!loading ?
                <>
                    <button className="google-button-wrapper" onClick={handleGoogleLoginClick}>
                        <span className="icon-panel">
                            <img src={googleIcon} alt="Google Icon" />
                        </span>
                        <span className="border-left">Continue with Google</span>
                    </button>
   
                    {/* <FacebookLogin
                        appId={FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields="name,email"
                        callback={responseFacebook}
                        render={renderProps => (
                            <div className="facebook-btn">
                            <FacebookLoginButton text="" icon='' onClick={renderProps.onClick}>
                                <img src={facbookIcon} />
                                <span className="border-left">Continue with Facebook</span>
                            </FacebookLoginButton>
                        </div>
                          )}
      
    /> */}
                </>
                :
                <Loader />
            }
        </div>
    );
};

export default SocialLogin;
