import React, { useEffect, useRef, useState } from 'react';
import { AppConstants, EventConstants, STREAM_DATA } from '../constants';
import Hls from 'hls.js';
import { loadVideo } from '../utils/LoadVideo';
import Loader from 'src/components/loader/loader';
import eventEmitter from '../utils/VoltaEventEmitter';
import {
  adjustVolume,
  handleProgress,
  toggleMute,
  togglePlayPause,
} from '../utils/PlayerControls';

interface MultiVideoViewProps {
  gridValue: string; // We are using this coz we are handling all the multi view i.e corner/feature/etc in single component, this will help us to understand which view mode is active
  data: STREAM_DATA[];
  setData: React.Dispatch<React.SetStateAction<STREAM_DATA[]>>;
  liveVideoUrls: STREAM_DATA[];
  setGridValue: React.Dispatch<React.SetStateAction<string>>;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  isFullScreen: boolean;
  showControls: boolean;
}

const MultiVideoView: React.FC<MultiVideoViewProps> = ({
  gridValue,
  data,
  setData,
  liveVideoUrls,
  setGridValue,
  setIsPlaying,
  setIsMuted,
  audioRef,
  isFullScreen,
  showControls,
}) => {
  var hlsInstance: Hls[];
  const videoRefs = useRef<HTMLVideoElement[]>([]);
  const [mediaAttached, setMediaAttached] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  

  const toggleGrid = (index: number) => {
    setData([liveVideoUrls[index]]);
    setGridValue(AppConstants.PLAYER_MODE_SOLO_TITLE);
  };

  const togglePlay = () => {
    togglePlayPause(videoRefs, audioRef, setIsPlaying);
  };

  const toggleMuteE = (data: any) => {
    toggleMute(videoRefs, audioRef, setIsMuted, data.wasMuted);
  };

  const adjustVolumeE = (data: any) => {
    adjustVolume(videoRefs, audioRef, setIsMuted, data.volume);
  };

  const handleMultiViewProgress = () => {
    handleProgress(videoRefs);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      // case 'Enter':
      case ' ':
        togglePlayPause(videoRefs, audioRef, setIsPlaying);
        break;
      // case 'f':
      //   toggleFullScreen();
      //   break;
      // case 'm':
      //   toggleMuteE({ wasMuted: videoRefs.current[0].muted }); // Pass the required data
      //   break;
      case 'ArrowUp':
        adjustVolumeE({
          volume: Math.min(1, (videoRefs.current[0].volume || 0) + 0.1),
        }); // Increase volume
        break;
      case 'ArrowDown':
        adjustVolumeE({
          volume: Math.max(0, (videoRefs.current[0].volume || 0) - 0.1),
        }); // Decrease volume
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (videoRefs.current) {
      let hlsInstance = loadVideo(data, videoRefs);
      hlsInstance[0].on(
        Hls.Events.MEDIA_ATTACHED,
        function (_event: any, data: any) {
          setMediaAttached(true);
          setIsLoading(false);
        }
      );
    }

    return () => {
      if (hlsInstance) {
        hlsInstance.forEach((element) => {
          element.destroy();
        });
      }
    };
  }, [videoRefs, data]);

  useEffect(() => {
    if (mediaAttached) {
      eventEmitter.on(EventConstants.TOGGLEPLAYPAUSE, togglePlay);
      eventEmitter.on(EventConstants.TOGGLEMUTE, toggleMuteE);
      eventEmitter.on(EventConstants.ADJUSTVOLUME, adjustVolumeE);
      // document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (mediaAttached) {
        eventEmitter.off(EventConstants.TOGGLEPLAYPAUSE, togglePlay);
        eventEmitter.off(EventConstants.TOGGLEMUTE, toggleMuteE);
        eventEmitter.off(EventConstants.ADJUSTVOLUME, adjustVolumeE);
        // document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [mediaAttached]);

  return (
    <div
      className={`threeByThreeVideoViewContainer ${
        gridValue === AppConstants.TWO_TILTED_VIEW_TITLE
          ? 'twotiltedview'
          : gridValue === AppConstants.PLAYER_MODE_TWO_BY_TWO_TITLE
          ? 'fourboxview'
          : gridValue.toLowerCase().trim()
      }`}
    >
      {isLoading && <Loader />}
      {data.map((asset, index) => (
        <div key={index} className="repeat-video">
          <video
            ref={(ref) => {
              if (videoRefs.current) {
                videoRefs.current[index] = ref as HTMLVideoElement;
              }
            }}
            controls={false}
          style={{ pointerEvents: 'none', cursor: 'pointer' }}
          controlsList="nofullscreen nopictureinpicture"
          muted
            autoPlay
            className="videoContainer"
            width="100%"
            height="100%"
              onClick={(e) => {
              e.stopPropagation();
              console.log('index:', index);
              toggleGrid(index);
            }}
            onTimeUpdate={handleMultiViewProgress}
            onMouseEnter={() => {
              setHoveredIndex(index);
            }}
            onMouseLeave={() => {
              setHoveredIndex(null);
            }}
          ></video>
          {/* Overlay */}
          {/* Media Details */}
          {isFullScreen ? (
            // Fullscreen mode
            showControls && (
              <div
                className="mediaDetailOverlay fullscreen"
                style={{ display: hoveredIndex === index ? 'block' : 'none' }}
              >
                {/* <div className="topLine"></div> */}
                <span className="videoTitle">{asset.meta_key}</span>
                <img src="/images/icons/Icons-28.png" className="overlayLogo" />
              </div>
            )
          ) : (
            // Normal mode
            <div
              className="mediaDetailOverlay"
              // className={`mediaDetailOverlay`}
              // style={{ display: hoveredIndex === index ? 'block' : 'none' }}
            >
              <div className="topLine"></div>
              <span className="videoTitle">{asset.meta_key}</span>
              <img src="/images/icons/Icons-28.png" className="overlayLogo" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MultiVideoView;
