import Background from "src/assets/background.png";
import logo from "src/assets/Volta_logo-login.png";
import "./back-ground-page-wrapper.scss";
import { useNavigate } from "react-router-dom";
const BackGroundPageWrapper = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="comman-container">
      <img className="comman-bg-img" src={Background} alt="" />
      <div className="logo-wrapper" onClick={() => navigate(`/home`)}>
        <img src={logo} alt="bg-image" className="logo-img-container" />
      </div>
      <div className="login-line-wrapper"></div>
      <div>{props.children}</div>
    </div>
  );
};

export default BackGroundPageWrapper;
