import React from 'react';
import { useCountdown } from './useCountdown'; 

interface WaitingRoomProps {
  eventName: string;
  eventPoster: string;
  startTime: string;
  endTime: string;
}

const WaitingRoom: React.FC<WaitingRoomProps> = ({
  eventName,
  eventPoster,
  startTime,
}) => {
  const countdown = useCountdown(startTime);

  const dynamicBgUrl = () => {
    return {
      background: `url(${eventPoster}) no-repeat`,
    };
  };

  return (
    <div className="waitingRoomContainer" style={dynamicBgUrl()}>
      <div className="waitingRoomTop">
        <span>YOUR VOLTA EXPERIENCE BROUGHT TO YOU IN PARTNERSHIP WITH</span>
        <span className="eventName">{eventName}</span>
      </div>
      <div className="waitingRoomBottom">
        <span>WE'RE GETTING CLOSE</span>
        <div className="timerContainer">
          <div className="leftLine"></div>
          <div className="countDownTimer">{countdown}</div>
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;
