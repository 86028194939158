import Hls from 'hls.js';
import mux from "mux-embed";

// Common function to load video
export const loadVideo = (
  videoUrls: { meta_key: string; meta_value: string }[],
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>
): Hls[] => {
  return videoUrls.map((data, index) => {
    const hlsInstance = new Hls();
    const Source = data.meta_value;
    const videoElement = document.querySelector('video') as HTMLVideoElement & {
      webkitShowPlaybackTargetPicker?: () => void;
    };
    hlsInstance.loadSource(data.meta_value);
    hlsInstance.attachMedia(videoRefs.current[index]!);

    hlsInstance.on(
      Hls.Events.MEDIA_ATTACHED,
      function (_event: any, data: any) {
      if ( videoElement.webkitShowPlaybackTargetPicker) {
        videoRefs.current[index].src = Source;
        }
      }
    );

    try {
      hlsInstance.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
  
          switch(data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error('Network error:', data);
              hlsInstance.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error('Media error:', data);
              hlsInstance.recoverMediaError();
              break;
            default:
              hlsInstance.destroy();
              break;
          }
        }
      });
      mux.monitor(videoRefs.current[index]!, {
        debug: false,
        hlsjs: hlsInstance,
        Hls: Hls,
        data: {
          video_id: '2',
          viewer_user_id: localStorage.getItem('userID'),
          env_key: "ud1bkct6432g48rj7hijhgbt1", // required
          player_name: "Single Player",
          video_title: 'Freedom Mortgage Pavilion N2 Event',
          video_stream_type: 'on-demand', // 'live' or 'on-demand'
        }
      });
    } catch (error) {
      console.error("Mux monitoring setup failed", error);
    }
    return hlsInstance;
  });
};
