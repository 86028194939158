import React, { useEffect, useState } from 'react';

const calculateTimeLeft = () => {
    const targetDate = new Date('2024-07-20T19:30:00-04:00');
    const difference = +targetDate - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    const day = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const mins = Math.floor((difference / 1000 / 60) % 60);
    const secs = Math.floor((difference / 1000) % 60);

    timeLeft = {
      ...(day > 0 && { day }),
      ...(hours > 0 && { hours }),
      ...(mins > 0 && { mins }),
      secs,
    };
  }

  return timeLeft;
};

const CountdownTimer = ({ setTimeLeftString }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      const timeString = Object.keys(newTimeLeft)
        .map(interval => {
          if (interval === 'hours' && newTimeLeft[interval] === 0) {
            return null;
          }
          if (interval === 'mins' && newTimeLeft[interval] === 0) {
            return null;
          }
          let intervalLabel = interval;
          if (interval === 'hours' && newTimeLeft[interval] < 1) {
            intervalLabel = 'hour';
          }
          if (interval === 'mins' && newTimeLeft[interval] < 1) {
            intervalLabel = 'min';
          }
          return `${newTimeLeft[interval]} ${intervalLabel}`;
        })
        .filter(Boolean)
        .join(' ');

      setTimeLeftString(timeString);

      if (Object.keys(newTimeLeft).length === 0) {
        setHasEnded(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, setTimeLeftString]);

  if (hasEnded) {
    setTimeLeftString('');
    return null; // Timer has ended, return null to hide it
  }

  return null; // The timer does not render anything itself
};

export default CountdownTimer;
