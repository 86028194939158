import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useVerifyEmailMutation,
  useVerifyOTPMutation,
  useRegisterUserMutation,
  useUserLoginMutation,
  useSendOTPMutation,
} from "src/redux/api/authAPI";
import {
  useProfileMutation,
  useUpdateProfileMutation,
  useUserNavigationMutation,
} from "src/redux/api/usersAPI";
import decodeJwtToken from "src/utils/decode-jwt-token";
import SocialLogin from "src/components/social-login/social-login";
import VERIFY from "src/assets/verify-email.png";
import lines from "src/assets/line-or.svg";
import {
  getLoggedInUserInfo,
  setAuthToken,
  setGuestUser,
  setUsertokenId,
  getuseridToken,
  getAuthToken,
} from "src/redux/service/auth";
import { useDispatch, useSelector } from "react-redux";
import "./sign-in.scss";
import BackGroundPageWrapper from "src/components/back-ground-page-wrapper/back-ground-page-wrapper";
import validateEmail from "src/utils/validate-email";
import regex from "src/enums/const";
import { LOGIN_SOURCE } from "src/config/config";
import Loader from "src/components/loader/loader";
import { ERROR_MESSAGE } from "src/enums/error-msg";
import { RootState } from "src/redux/store/store";
import { NavLink } from "react-router-dom";
import analytics from "src/config/firebase-config";
import { logEvent, setUserId } from "firebase/analytics";
import { setUserProfile } from "src/redux/reducer/authSlice";
import { scrollToTop } from "src/utils/srcoll-to-top";
import { useLocation } from 'react-router-dom';

const Register = () => {
  const textRegx = /^[a-zA-Z\s]*$/;
  const { previousRoute } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRef: any = "";
  const [timer, setTimer] = useState(60);
  const userId = getuseridToken();
  const [verifyEmail] = useVerifyEmailMutation();
  const [verifyOTP] = useVerifyOTPMutation();
  const [registerUser] = useRegisterUserMutation();
  const [userNavigation] = useUserNavigationMutation();
  const [userLogin] = useUserLoginMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [sendOTP] = useSendOTPMutation();
  const [email, setEmail] = useState(emailRef);
  const [otp, setOTP] = useState("");
  const [isOtpEnable, setOptEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null || "");
  const [isUserForm, setIsUserForm] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [profile] = useProfileMutation();
  const tokenRef = getAuthToken();
  const location = useLocation();
  const isUser = getLoggedInUserInfo();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    const isEmailValid: any = validateEmail(email);
    if (isEmailValid) {
      setError("");
    }

  };

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isNumber = /^\d*$/;
    if (isNumber.test(inputValue) && inputValue.length <= 6) {
      setOTP(inputValue);
    }
  };

  const handleEmailBlur = async () => {
    setError("");
    if (!email) {setError(ERROR_MESSAGE.EMAIL_VALIDATION_MSG); return};
    const isEmailValid: any = validateEmail(email);
    if (isEmailValid) {
      try {
        setLoading(true);
        const response: any = await verifyEmail({
          emailId: email,
          loginSource: LOGIN_SOURCE.SOURCE_3,
        });
        const decodeToken = decodeJwtToken(response.data);
        if (decodeToken.success) {
          const response: any = await userLogin({
            emailId: email,
            loginSource: LOGIN_SOURCE.SOURCE_3,
          });
          const decodeToken = decodeJwtToken(response.data);
          if (decodeToken.success) {
            setOptEnable(true);
            setIsUserLogin(true);
            setTimer(60);
          } else {
            setError(decodeToken.message);
          }
        } else {
          // register user flow if no email in db
          const response: any = await registerUser({
            emailId: email,
            loginSource: LOGIN_SOURCE.SOURCE_3,
          });
          const decodeToken = decodeJwtToken(response.data);
          if (decodeToken.success) {
            setOptEnable(true);
            setIsUserLogin(false);
            setTimer(60);
          } else {
            setError(decodeToken.message);
          }
        }
      } catch (error: any) {
        setError(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      setError(ERROR_MESSAGE.EMAIL_VALIDATION_MSG);
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateUserName(firstName) && validateUserName(lastName)) {
      try {
        setLoading(true);
        // const { userId } = getLoggedInUserInfo();
        const data: any = await updateProfile({ firstName, lastName, userId });
        setLoading(false);
        const token = data.data;
        const decodedToken = decodeJwtToken(token);
        if (decodedToken.success) {
          await getProfile(tokenRef);
          setIsUserForm(false);
          const data = await getLoggedInUserInfo();
          logEvent(analytics, `register user with details`, {
            user_name: data?.name || `${firstName} ${lastName}`,
            user_email: data?.email,
            user_id: data?.userId,
            user_login_Type: "Formal Login",
          });
          navigate(previousRoute ? `${previousRoute}` : `/home`);
        } else {
          setError(decodedToken.message);
        }
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    } else {
      setError(ERROR_MESSAGE.USER_INFO_ERROR);
    }
  };

  const validateNumber = (number: any) => {
    return regex.test(number);
  };

  const validateUserName = (name: string) => {
    return textRegx.test(name);
  };

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = async () => {
    await sendOTP({ emailId: email });
    setTimer(60);
  };

  const handleOtp = async () => {
    if (!otp) return;
    if (otp && validateNumber(otp)) {
      try {
        setLoading(true);
        const data: any = await verifyOTP({
          emailId: email,
          otp,
          loginSource: LOGIN_SOURCE.SOURCE_3,
        });
        setLoading(false);
        const token = data.data;
        const decodedToken = decodeJwtToken(token.toString());
        if (decodedToken.success) {
          setError("");
          const user_id = decodeJwtToken(
            decodedToken?.data[0]?.token.toString()
          );
          setUsertokenId(decodedToken?.data[0]?.token);
          // setAuthToken(decodedToken?.data[0]?.token);
          setGuestUser(false);
          if (isUserLogin) {
            await getProfile(tokenRef);
            setIsUserForm(false);
            const data = await getLoggedInUserInfo();
            logEvent(analytics, `login user with details FROM SIGN IN`, {
              user_name: data?.name,
              user_email: data?.email,
              user_id: data?.userId,
              user_login_Type: "Formal Login",
            });
            navigate(previousRoute ? `${previousRoute}` : `/home`);
          } else {
            setIsUserForm(true);
          }
        } else {
          setError(decodedToken.message);
        }
      } catch (error: any) {
        setError(error.message);
        setLoading(false);
      }
    } else {
      setError(ERROR_MESSAGE.OTP_VALIDATION_SIX_DIGIT_MSG);
    }
  };

  const getProfile = async (token: any) => {
    setLoading(true);
    const UserID = getuseridToken();
    const profileInfo: any = await profile({ UserID });
    const profileData = decodeJwtToken(profileInfo.data);
    dispatch(setUserProfile(profileData));
    scrollToTop();
    setLoading(false);
  };

  useEffect(() => {
    if (isUser && location.pathname === '/sign-in') {
      navigate('/home'); // if user is logged in & try to use back button to go back(it will redirect user to home page)
    }
  }, [isUser, location]);

  return (
    <BackGroundPageWrapper>
      {!loading ? (
        <div className="main-register">
          {!isOtpEnable && !isUserForm && (
            <div className="create-account-panel">
              <div className="create-account-panel-heading">
                Connect with us to get your tickets.
              </div>
              <div className="create-account-panel-description">
                Tickets need to be registered to a single user to ensure the
                quality of your experience.
              </div>
              <div className="create-account-btn-first">
                <SocialLogin />
              </div>
              <div className="devide-lines">
                <hr />
                <span>or</span> <hr />
                {/* <img src={lines} alt="line-or-icon" /> */}
              </div>
            </div>
          )}

          {isOtpEnable && !isUserForm && (
            <div className="verify-email-panel">
              <div className="verify-email-panel-heading">
                Verify your email
              </div>
              <div className="verify-email-panel-description">
                {`We just sent a 6-digit code to ${email}, enter it below`}
              </div>
            </div>
          )}

          {isUserForm && (
            <div className="create-email-panel">
              <div className="create-email-panel-heading">
                Create Your Profile
              </div>
              <div className="cretate-email-panel-description">
                Please enter your information
              </div>
            </div>
          )}

          <div className="Middel-box">
            <form onSubmit={handleLogin}>
              <div className="fill-box">
                {!isOtpEnable && !isUserForm && (
                  <>
                    <div className="fill-box-heading">Your email address</div>
                    <input
                      type="email"
                      placeholder="email@address.com"
                      value={email}
                      onChange={handleEmailChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleEmailBlur();
                        }
                      }}
                    />
                  <button
                    className="option-btn-email"
                    type="button"
                    onClick={handleEmailBlur}
                    disabled={loading}
                  >
                    Submit
                  </button>
                  </>
                )}
       

              </div>
              {isOtpEnable && !isUserForm && (
                <>
                  <div className="fill-box">
                    <div className="fill-box-heading">Code</div>
                    <input
                      type="text"
                      placeholder="Enter Code"
                      value={otp}
                      onChange={handleOTPChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleOtp();
                        }
                      }}
                    />
                  </div>
                  {error && isOtpEnable ? (
                    <div style={{ margin: "10px 0px 0px 0px" }}>{error}</div>
                  ) : (
                    <div style={{ margin: "10px 0px 0px 0px" ,height:'21px'}}>{''}</div>
                  )}

                  <div className="sub-description">
                    Don't see a code?{" "}
                    <span className="sub-des-dark">Resend to email.</span>
                  </div>
                  
                    <button
                      type="button"
                      className="resend-code"
                      onClick={handleResendClick}
                      disabled={timer > 0}
                    >
                      {/* {timer > 0 && ` ${timer} `} */}
                      {timer > 0 ? `Resend in ${timer} seconds` : 'Resend code'}
                    </button>
                 
                  {/* <br /> */}

                  {/* <div className="wrapper-btn"> */}
                  <button
                    type="button"
                    onClick={handleOtp}
                    className="option-btn-email"
                    disabled={loading && validateNumber(otp)}
                  >
                    {/* <img src={VERIFY} alt="Verfiy-email" /> */}
                    verify email
                  </button>
                  {/* </div> */}
                </>
              )}

              {isUserForm && (
                <div className="fill-box">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="mb-15"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <button
                    type="submit"
                    className="option-btn-email"
                    disabled={loading}
                  >
                    Submit
                  </button>
                  {error ? 
                    <div style={{ margin: "20px 0px 0px 0px" }}>{error}</div>:<div style={{ margin: "20px 0px 0px 0px" ,height:'21px'}}>{' '}</div>
                  }
                </div>
              )}
              {error && !isOtpEnable ?
                <div style={{ margin: "10px 0px 0px 0px" }}>{error}</div>:<div style={{ margin: "10px 0px 0px 0px",height:'21px' }}>{' '}</div>
              }
            </form>
          </div>

          {!isOtpEnable && (
            <div className="bottom-section">
              <div className="bottom-section-info">
                By clicking the button above, you agree to our{" "}
                <span className="bottom-section-subinfo">
                  {" "}
                  <NavLink to="/terms-and-conditions/3">Terms of Use </NavLink>
                </span>
                and
                <span className="bottom-section-subinfo">
                  {" "}
                  <NavLink to="/privacy/2">Privacy Policy.</NavLink>
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </BackGroundPageWrapper>
  );
};

export default Register;
