import Hls from 'hls.js';

// Common function to load video
export const loadAudio = (
  audioUrl: string,
  audioRef: React.MutableRefObject<HTMLAudioElement | null>
): Hls => {
  const hlsInstance = new Hls();
  hlsInstance.loadSource(audioUrl);
  hlsInstance.attachMedia(audioRef.current!);
  return hlsInstance;
};
