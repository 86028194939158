import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import decodeJwtToken from "src/utils/decode-jwt-token";
import humburger from "src/assets/Burger-icon.svg";
import cross from "src/assets/menu-cross.svg";
import Out from "src/assets/logout_FILL0_wgh.svg";
import "./header.scss";
import { setUserNavigation, setUserProfile } from "src/redux/reducer/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/store";
import { getAuthToken, getGuestUser, logout, getuseridToken } from "src/redux/service/auth";
import {
  useProfileMutation,
  useUserNavigationMutation,
} from "src/redux/api/usersAPI";
import { userInfo } from "os";

const MenuItems = (props: any) => {
  const navigate = useNavigate();
  const [profile] = useProfileMutation();
  const dispatch = useDispatch();
  const { isGuest } = props;
  const { userProfile } = useSelector((state: RootState) => state.auth);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const token = getAuthToken();
  const UserID = getuseridToken();
  const showProfileRef: any = useRef(null);
  const [apiCalled, setApiCalled] = useState(false);

  useEffect(() => {
    setApiCalled(true);
  }, []);

  useEffect(() => {
    if (apiCalled && isGuest === "false" && userProfile && !userProfile.data) {
      getProfile(token);
    }
  }, [isGuest, apiCalled]);

  const getProfile = async (token: any) => {  
    props.onLoaderHandle(true); 
    const profileInfo: any = await profile({ UserID });
    const profileData = decodeJwtToken(profileInfo.data);
    dispatch(setUserProfile(profileData));
    props.onLoaderHandle(false);
  };

  const onProfileToggle = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showProfileRef.current &&
        !showProfileRef.current.contains(event.target)
      ) {
        setShowProfile(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    if (props?.onMenuItemHandle) {
      props.onMenuItemHandle();
    }
  };

  return (
    <>
      <div className="static-menu">
        <NavLink onClick={handleLinkClick} to="/about">
          ABOUT
        </NavLink>


        {/* <NavLink onClick={handleLinkClick} to="/experience-the-demo">
          EXPERIENCE THE DEMO
        </NavLink> */}

        {/* <NavLink onClick={handleLinkClick} to="/upcoming-event">
          SHOWS
        </NavLink> */}

        {userProfile?.data &&
        !props.hamburger &&
        userProfile?.data[0]?.emailId ? (
          <div
            ref={showProfileRef}
            className="profile-wrapper"
            onClick={() => {
              onProfileToggle();
            }}
          >
            {userProfile?.data[0]?.emailId.charAt(0).toUpperCase()}
          </div>
        ) : (
          <NavLink className="btn-signin" to="/sign-in">
            SIGN IN
          </NavLink>
        )}

        {props.hamburger &&
          (isGuest === "true" ? (
            <div className="sign-out-wrapper">
              <div className="sign-title" onClick={() => navigate(`/sign-in`)}>
                SIGN IN
              </div>
            </div>
          ) : (
            <div className="sign-out-wrapper" onClick={logout}>
              <div className="sign-title" onClick={logout} >Sign out</div>
              {/* <div className="sign-out-icon" >
                <img src={Out} alt="" onClick={logout} />
              </div> */}
            </div>
          ))}
      </div>
      {userProfile?.data && userProfile?.data[0]?.emailId && showProfile && (
        <div className="sign-new-wrapper">
          <div className="sing-btn-wrapper">
            <div className="sing-icon">
              <img src={Out} alt="sing-iocn"  onClick={logout} />
            </div>

            <div
              className="sing-out"
              onClick={() => {
                logout();
              }}
            >
              Sign Out
            </div>
          </div>
        </div>
      )}
      <div className="menu-footer-last">&#169;2024 VOLTA MEDIA GROUP LLC</div>
    </>
  );
};

const Header = ({onLoaderHandle}: any) => {
  const isGuest = getGuestUser();
  const token = getAuthToken();
  const [userNavigation] = useUserNavigationMutation();
  const { navigationList } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [hamburger, setHamburger] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    async function userNavigationList() {
      const response = await userNavigation({ token });
      const decodedToken = decodeJwtToken(response.toString());
      const { data } = decodedToken?.data;
      dispatch(setUserNavigation(data));
    }

    if (token && isGuest && isGuest === "false" && navigationList.length > 0) {
      userNavigationList();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dislayHamburger = () => {
    setHamburger((prev) => !prev);
  };

  return (
    <div className="nevigation-wrapper">
      <div onClick={dislayHamburger} className="hamburger">
        {!hamburger ? (
          <img src={humburger} alt="humburger" />
        ) : (
          <img src={cross} alt="humburger" />
        )}
      </div>
      <div className="Logo-wrapper">
        {isMobile ? (
          <NavLink to="/home">
            {navigationList?.data?.logo && (
              <img src={navigationList.data.logo} alt="mobile-logo" />
            )}
          </NavLink>
        ) : (
          <NavLink to="/home">
            {navigationList?.data?.logo && (
              <img src={navigationList.data.logo} alt="logo" />
            )}
          </NavLink>
        )}
      </div>
      <div className="menu-wrapper">
        <MenuItems
          isGuest={isGuest}
          navigationList={navigationList}
          hamburger={hamburger}
          onLoaderHandle={onLoaderHandle}
        />
      </div>
      <div
        style={{
          display: hamburger ? "flex" : "none",
        }}
        className="menu-mobile"
      >
        <MenuItems
          isGuest={isGuest}
          navigationList={navigationList}
          hamburger={hamburger}
          onMenuItemHandle={dislayHamburger}
          onLoaderHandle={onLoaderHandle}
        />
      </div>
    </div>
  );
};
export default Header;