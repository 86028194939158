import React, { useEffect, useState } from "react";
import Vlogo from "src/assets/V-logo.svg";
import Watch from "src/assets/Group-323.png";
import background from "src/assets/background.png";
import PalyerInfo from "src/assets/palyer-details.png";

import Never from "src/components/never-miss-out/never-miss-out";
import Faq from "src/components/faq/faq";
import { useNavigate } from "react-router-dom";
import "./experience-the-demo.scss";
import { scrollToTop } from "src/utils/srcoll-to-top";
import Switching from "src/assets/Instant-Switching.png";
import Camerview from "src/assets/VOLTA-Camera-View.png";
import Chat from "src/assets/VOLTA-Chat.png";
import Quality from "src/assets/VOLTA-Quality.png";
import { ReactComponent as Qualityhd } from "src/assets/qualityhd.svg";
import { ReactComponent as Grid } from "src/assets/grid.svg";
import { ReactComponent as LiveChat } from "src/assets/livechat.svg";
import { ReactComponent as Control } from "src/assets/controlview.svg";
import Mobileinfo from "src/assets/mobile-plyaer-info.png";
import analytics from 'src/config/firebase-config'; 
import { logEvent } from "firebase/analytics";

const Experience = () => {
  const navigate = useNavigate();
  scrollToTop();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    logEvent(analytics, 'FireBase experience the demo page called');
  }, []);

  return (
    <>
      <div className="Main-experience-conatiner">
        <div className="solo-view-details" style={{ backgroundImage: `url(${background})` }}>
          <div className="solo-view-bg">
            <img src={background} alt="backround-img" />
          </div>
          <div className="features-wrapper">VOLTA FEATURES</div>
          <div className="player-info-wrapper">
            {isMobile ? (
              <img className="player-info" src={Mobileinfo} alt="palyer-info" />
            ) : (
              <img className="player-info" src={PalyerInfo} alt="palyer-info" />
            )}
          </div>
        </div>

        <div className="conatiner-feature-wrapper">
          <div className="feature-wrapper-inner">
            <div className="feature-description-wrapper">
              <div className="feature-heading">
                <div className="feature-title-heading">Quality Control</div>
                <span>
                  <Qualityhd />
                </span>
              </div>
              <div className="feature-details">
                Experience a new level of immersion with our video player's
                groundbreaking Audio and Video Quality Controls. Fine-tune your
                sound with crystal-clear highs, while diving into visuals with
                stunning clarity and vibrant colors. Elevate every moment of
                your entertainment journey with just a few clicks.
              </div>
            </div>
            <div className="feature-details-box">
              <img src={Quality} alt="chat-view" />
            </div>
          </div>
        </div>

        <div className="line"></div>
        <div className="conatiner-feature-wrapper-two">
          <div className="feature-wrapper-inner-two">
            <div className="feature-details-box-two">
              <img src={Switching} alt="chat-view" />
            </div>
            <div className="feature-description-wrapper-two">
              <div className="feature-heading-two">
                <div className="title-feature">Instantaneous Switching</div>
                <span>
                  <Grid />
                </span>
              </div>
              <div className="feature-details-two">
                Control your own event experience, where every frame, every
                angle, every moment is yours to command. With a swipe of a
                finger or the click of a button, you're instantly switching from
                one camera angle to another, seamlessly navigating through your
                event, giving you an experience like never before.
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="line-wrapper"></div>
        <div className="conatiner-feature-wrapper">
          <div className="feature-wrapper-inner">
            <div className="feature-description-wrapper">
              <div className="feature-heading">
                <div className="feature-title-heading">Share the Experience</div>
                <span>
                  <LiveChat />
                </span>
              </div>
              <div className="feature-details">
                Join the community and share your experience as you connect with
                fellow fans in real-time commentary during the event. Share in
                the heart-stopping moments, jaw-dropping plays, and nail-biting
                suspense. It’s your show and your experience – create memories
                that echo far beyond the final whistle or encore.
              </div>
            </div>
            <div className="feature-details-box">
              <img src={Chat} alt="chat-view" />
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="conatiner-feature-wrapper-two">
          <div className="feature-wrapper-inner-two">
            <div className="feature-details-box-two">
              <img src={Camerview} alt="control-view" />
            </div>
            <div className="feature-description-wrapper-two">
              <div className="feature-heading-two">
               <div className="title-feature"> Control the View</div>
                <span>
                  <Control />
                </span>
              </div>
              <div className="feature-details-two">
                Set the cameras you want to see where you want to see them. Step
                into the director’s chair and control the video layout as you
                wield the power to create your visual experience in real-time.
                Enjoy a new level of interaction and immersion by choosing any
                broadcast angle you desire at any given moment.
              </div>
            </div>
          </div>
        </div>
        <div className="line-panel"></div>
        <div className="upcoming-wrapper">
          <div className="upcoming-heading">Experience More</div>
          <button
            className="shows-btn"
            onClick={() => navigate(`/upcoming-event`)}
          >
            Shows
          </button>
        </div>
      </div>
    </>
  );
};

export default Experience;
