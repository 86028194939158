import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from '../ProgressBar';
import eventEmitter from '../../utils/VoltaEventEmitter';
import BottomLeft from './BottomLeft';
import BottomCenter from './BottomCenter';
import BottomRight from './BottomRight';
import { STREAM_DATA } from '../../constants';

interface BottomMenuProps {
  data: STREAM_DATA[];
  setData: React.Dispatch<React.SetStateAction<STREAM_DATA[]>>;
  liveVideoUrls: STREAM_DATA[];
  isFullScreen: boolean;
  gridValue: string;
  setGridValue: React.Dispatch<React.SetStateAction<string>>;
  isStreamLive: boolean;
  totalDuration: number;
  INTERVAL: number;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  toggleFullScreen: () => void;
  isMutliView: number;
  signal: string | null;
  assetName: string;
}

const BottomMenu: React.FC<BottomMenuProps> = ({
  data,
  setData,
  liveVideoUrls,
  isFullScreen,
  gridValue,
  setGridValue,
  isStreamLive,
  totalDuration,
  INTERVAL,
  isPlaying,
  setIsPlaying,
  isMuted,
  setIsMuted,
  volume,
  setVolume,
  toggleFullScreen,
  isMutliView,
  signal,
  assetName,
}) => {
  // Constants
  const progressSliderRef = useRef(null);

  // States
  const [currentTime, setCurrentTime] = useState<number>(0);

  const handleProgress = (data: any) => {
    setCurrentTime(data.value);
    if (progressSliderRef.current != null) {
      let timeSlider: any = progressSliderRef.current;
      if (timeSlider.value !== data.value) {
        timeSlider.value = data.value;
      }
    }
  };

  useEffect(() => {
    eventEmitter.on('handleProgress', handleProgress);

    return () => {
      eventEmitter.off('handleProgress', handleProgress);
    };
  }, []);

  return (
    <div className="videoPlayerBottom">
      <div className="bottomMenuTop">
        <ProgressBar
          ref={progressSliderRef}
          totalDuration={totalDuration}
          isStreamLive={isStreamLive}
        />
      </div>
      <div className="bottomMenuBottom">
        <BottomLeft
          isStreamLive={isStreamLive}
          totalDuration={totalDuration}
          currentTime={currentTime}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          volume={volume}
          setVolume={setVolume}
        />
        <BottomCenter assetName={assetName} />
        <BottomRight
          isFullScreen={isFullScreen}
          gridValue={gridValue}
          liveVideoUrls={liveVideoUrls}
          setGridValue={setGridValue}
          toggleFullScreen={toggleFullScreen}
          data={data}
          setData={setData}
          isStreamLive={isStreamLive}
          isMutliView={isMutliView}
          signal={signal}
        />
      </div>
    </div>
  );
};

export default BottomMenu;
