import React from 'react';

interface BottomCenterProps {
  assetName: string;
}

const BottomCenter: React.FC<BottomCenterProps> = ({ assetName }) => {
  return (
    <ul className="bottomCenter">
      <li>
        <span className="videoTitle">{assetName}</span>
      </li>
    </ul>
  );
};

export default BottomCenter;
