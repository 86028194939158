export class AppConstants {
  static USE_ATEME = true;

  static PLAYER_MODE_THREE_BY_THREE = '3x3';

  static PLAYER_MODE_TWO_BY_TWO = '2x2';

  static PLAYER_MODE_SOLO = '1x1';

  static PLAYER_MODE_THREE_BY_THREE_TITLE = 'Mosaic View';

  static PLAYER_MODE_TWO_BY_TWO_TITLE = '4 Box View';

  static PLAYER_MODE_SOLO_TITLE = 'Solo View';

  static TWO_TILTED_VIEW_TITLE = '2 Tilted View';

  static CORNERS_VIEW_TITLE = 'Corners View';

  static FEATURE_VIEW_TITLE = 'Feature View';

  static TWO_BY_TWO_GRID_VALUE = '4';

  static THREE_BY_THREE_GRID_VALUE = '9';

  static PLAYER_MODE = AppConstants.PLAYER_MODE_THREE_BY_THREE;

  static PLAYER_INITIAL_GRID_VALUE =
    AppConstants.PLAYER_MODE === AppConstants.PLAYER_MODE_THREE_BY_THREE
      ? AppConstants.THREE_BY_THREE_GRID_VALUE
      : AppConstants.TWO_BY_TWO_GRID_VALUE;

  static ABR_ENABLED = true;

  static USE_NATIVE_HLS_ON_SAFARI = false;

  static BUFFERING_GOAL_IN_SECS = 30;

  static REBUFFERING_GOAL_IN_SECS = 5;

  static SOLO_GRID_VALUE = '1';

  static THREE_BY_THREE_VIDEO_VIEW_AUDIO_STREAM_INDEX = 5;

  static TWO_BY_TWO_VIDEO_VIEW_AUDIO_STREAM_INDEX = 1;

  static THREE_BY_THREE_VIDEO_VIEW_TITLE = 'DAVE MATTHEWS BAND';

  static TWO_BY_TWO_VIDEO_VIEW_TITLE = 'BRITISH BASKETBALL LEAGUE';

  static STREAM_TYPE = AppConstants.USE_ATEME
    ? 'application/vnd.apple.mpegurl'
    : 'video/mp4';

  static SOLO_VIDEO_VIEW_STREAM = AppConstants.USE_ATEME
    ? AppConstants.PLAYER_MODE === AppConstants.PLAYER_MODE_THREE_BY_THREE
      ? 'https://volta-demo.akamaized.net/hls/live/2105238/cam2/manifest.m3u8'
      : AppConstants.PLAYER_MODE === AppConstants.PLAYER_MODE_TWO_BY_TWO
      ? 'https://volta-demo.akamaized.net/hls/live/2105238/bbl1/manifest.m3u8'
      : 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4'
    : 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4';

  static SOLO_VIDEO_VIEW_STREAM_TITLE = AppConstants.USE_ATEME
    ? AppConstants.PLAYER_MODE === AppConstants.PLAYER_MODE_THREE_BY_THREE
      ? '1. CARTER BEUFORD DRUM CAM'
      : AppConstants.PLAYER_MODE === AppConstants.PLAYER_MODE_TWO_BY_TWO
      ? '1. BBL CAM ONE'
      : 'NA'
    : '1. What Car Can You Get For A Grand?';

  //static SOLO_VIDEO_VIEW_STREAM = "https://vg-republictvyupp.akamaized.net/ptnr-yupptv/v1/master/611d79b11b77e2f571934fd80ca1413453772ac7/vglive-sk-952092/main.m3u8";

  //static SOLO_VIDEO_VIEW_STREAM_TITLE = "REPUBLIC TV";

  static TWO_BY_TWO_VIDEO_VIEW_STREAMS = AppConstants.USE_ATEME
    ? [
        {
          name: '1. BBL CAM ONE',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/bbl1/manifest.m3u8',
        },
        {
          name: '2. BBL CAM TWO',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/bbl2/manifest.m3u8',
        },
        {
          name: '3. BBL CAM THREE',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/bbl3/manifest.m3u8',
        },
        {
          name: '4. BBL CAM FOUR',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/bbl4/manifest.m3u8',
        },
      ]
    : [
        {
          name: '1. Tears of Steel LIVE',
          src: 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
        },
        {
          name: '2. Oceans AES',
          src: 'http://playertest.longtailvideo.com/adaptive/oceans_aes/oceans_aes.m3u8',
        },
        {
          name: '3. Apple Bip Bop',
          src: 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8',
        },
        {
          name: '4. Parkour',
          src: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
        },
      ];

  static THREE_BY_THREE_VIDEO_VIEW_STREAMS = AppConstants.USE_ATEME
    ? [
        {
          name: '1. CARTER BEUFORD DRUM CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam1/manifest.m3u8',
        },
        {
          name: '2. BUDDY STRONG KEYBOARD CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam2/manifest.m3u8',
        },
        {
          name: '3. CROWD CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam3/manifest.m3u8',
        },
        {
          name: '4. DAVE MATHEWS CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam4/manifest.m3u8',
        },
        {
          name: '5. TIM REYNOLDS GUITAR CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam5/manifest.m3u8',
        },
        {
          name: '6. PROGRAM CUT',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam6/manifest.m3u8',
        },
        {
          name: '7. STEFAN LESSARD BASS CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam7/manifest.m3u8',
        },
        {
          name: '8. RESHAWN ROSS TRUMPET CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam8/manifest.m3u8',
        },
        {
          name: '9. FREE RANGE CAM',
          src: 'https://volta-demo.akamaized.net/hls/live/2105238/cam9/manifest.m3u8',
        },
      ]
    : [
        {
          name: '1. Tears of Steel LIVE',
          src: 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
        },
        {
          name: '2. Oceans AES',
          src: 'http://playertest.longtailvideo.com/adaptive/oceans_aes/oceans_aes.m3u8',
        },
        {
          name: '3. Apple Bip Bop',
          src: 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8',
        },
        {
          name: '4. Parkour',
          src: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
        },
        {
          name: '5. Long Tail Video',
          src: 'http://playertest.longtailvideo.com/adaptive/captions/playlist.m3u8',
        },
        {
          name: '6. ABC LIVE',
          src: 'http://content.uplynk.com/channel/e4d136bb61b1453a8817dc39c6e90201.m3u8',
        },
        {
          name: '7. Sintel',
          src: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
        },
        {
          name: '8. AlexSandBox',
          src: 'http://externaltests.dev.kaltura.com/player/sandBox/alex-sandBox/master.m3u8',
        },
        {
          name: '9. Folgers',
          src: 'http://cdnbakmi.kaltura.com/p/243342/sp/24334200/playManifest/entryId/0_uka1msg4/flavorIds/1_vqhfu6uy,1_80sohj7p/format/applehttp/protocol/http/a.m3u8',
        },
      ];

  static showLive = false;

  static M3U8 = AppConstants.showLive
    ? 'https://volta-demo.akamaized.net/hls/live/2105238/cam11/manifest.m3u8'
    : 'https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8';

  static SOLO_VIDEO_VIEW_VOD = [
    { meta_key: 'cam1', meta_value: AppConstants.M3U8 },
  ];

  static THREE_BY_THREE_VIDEO_VIEW_STREAMS_VODS = [
    {
      meta_key: 'cam1',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam2',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam3',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam4',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam5',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam6',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam7',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam8',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam9',
      meta_value: AppConstants.M3U8,
    },
  ];

  static TWO_BY_TWO_VIEW_STREAMS = [
    {
      meta_key: 'cam1',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam2',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam3',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam4',
      meta_value: AppConstants.M3U8,
    },
  ];

  static ONE_BY_TWO_VIEW_STREAMS = [
    {
      meta_key: 'cam1',
      meta_value: AppConstants.M3U8,
    },
    {
      meta_key: 'cam2',
      meta_value: AppConstants.M3U8,
    },
  ];
}

export class EventConstants {
  static MEDIAINFO = 'mediaInfo';
  static TOGGLEPLAYPAUSE = 'togglePlayPause';
  static FORWARD = 'forward';
  static REWIND = 'rewind';
  static HANDLEPROGRESS = 'handleProgress';
  static TOGGLEFULLSCREEN = 'toggleFullscreen';
  static SEEKING = 'seeking';
  static TOGGLEMUTE = 'toggleMute';
  static ADJUSTVOLUME = 'adjustVolume';
  static GETQUALITYLEVEL = 'getQualityLevel';
  static CHANGEQUALITY = 'changeQuality';
  static AIRPLAY = 'airplay';
  static CHROMECAST = 'chromecast';

}

export const voltaPink = '#ff6ce2';

export const CONTENT_TYPE = {
  VOD: 'vod',
  LIVE: 'live',
};

export interface STREAM_DATA {
  meta_key: string;
  meta_value: string;
}

export const INTERVAL = 10;

export const PUBNUB_SIGNALS = {
  IDLE: 'idle',
  STAND_BY: 'stand_by',
  STREAM_START: 'stream_start',
  STREAM_PAUSE: 'stream_pause',
  STREAM_END: 'stream_end',
  ON_DEMAND_READY: 'on_demand_ready',
};

export const Source = 'https://voltacontents.dcafecms.com/0a8d90b68fcee95e20579212cba3bc42/Cam6Crush.m3u8'
