import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import eventEmitter from '../utils/VoltaEventEmitter';
import { AppConstants, EventConstants, STREAM_DATA } from '../constants';
import { loadVideo } from '../utils/LoadVideo';
import {
  adjustVolume,
  forwardVideo,
  handleProgress,
  onSeek,
  rewindVideo,
  toggleMute,
  togglePlayPause,
} from '../utils/PlayerControls';
import { ReactComponent as Play } from '../../../assets/icons/Icons-21.svg'

interface SoloVideoViewProp {
  data: STREAM_DATA[];
  isFullScreen: boolean;
  isStreamLive: boolean;
  totalDuration: number;
  setTotalDuration: React.Dispatch<React.SetStateAction<number>>;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMediaOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  audioRef: React.MutableRefObject<HTMLAudioElement | null> | null;
  showControls: boolean;
  showMediaOverlay: boolean;
  isPlaying: boolean
}

const SoloVideoView: React.FC<SoloVideoViewProp> = ({
  data,
  isFullScreen,
  isStreamLive,
  totalDuration,
  setTotalDuration,
  setIsPlaying,
  setIsMuted,
  setShowMediaOverlay,
  audioRef,
  showControls,
  showMediaOverlay,
  isPlaying
}) => {
  const videoRef = useRef<HTMLVideoElement[]>([]);
  const [hlsInstance, setHlsInstance] = useState<Hls>();
  const [mediaAttached, setMediaAttached] = useState<boolean>(false);
  const [selectedQuality, setSelectedQuality] = useState<{
    bitrate: number;
    qualityLabel: string;
  }>({
    bitrate: -1,
    qualityLabel: 'Auto',
  });
  const [showPlayPause, setShowPlayPause] = useState(true);
  const castInstance = new Castjs();

  const handleSoloProgress = () => {
    handleProgress(videoRef);
  };

  const togglePlay = (event: any) => {
    const wasMuted = true;
    togglePlayPause(videoRef, audioRef, setIsPlaying);
    toggleMute(videoRef, audioRef, setIsMuted, wasMuted);
    if (!isPlaying) {
      setShowPlayPause(false);
    }
  };

  const forwardVideoE = (event: any) => {
    if (!isNaN(totalDuration) && !isStreamLive) {
      forwardVideo(videoRef, totalDuration, event.interval);
    }
  };

  const rewindVideoE = (event: any) => {
    if (!isStreamLive) {
      rewindVideo(videoRef, event.interval);
    }
  };

  const onSeekE = (event: any) => {
    onSeek(videoRef, event.seekingValue);
  };

  const toggleMuteE = (event: any) => {
    toggleMute(videoRef, audioRef, setIsMuted, event.wasMuted);
  };

  const adjustVolumeE = (event: any) => {
    adjustVolume(videoRef, audioRef, setIsMuted, event.volume);
  };

  const setTotalDurationOfMedia = () => {
    if (videoRef.current[0]) {
      setTotalDuration(videoRef.current[0].duration);
      if (videoRef.current[0]) {
        setTotalDuration(videoRef.current[0].duration);
      }
    }
  };

  const changeQuality = (data: {
    selectedOption: {
      bitrate: number;
      qualityLabel: string;
    };
  }) => {
    setSelectedQuality(data.selectedOption);
  };

  useEffect(() => {
    if (videoRef && data[0].meta_value) {
      const videoHlsInstance = loadVideo(data, videoRef)[0];
      setHlsInstance(videoHlsInstance);

      videoHlsInstance.on(
        Hls.Events.LEVEL_LOADED,
        function (_event: any, data: any) {
          setMediaAttached(true);
        }
      );
      videoHlsInstance.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        if (data.levels.length > 0) {
          let qualityArray: { bitrate: number; qualityLabel: string }[] = [];
          data.levels.forEach((element) => {
            qualityArray.push({
              bitrate: element.bitrate,
              qualityLabel:
                element['_attrs'][0].RESOLUTION?.split('x')[1] + 'p',
            });
          });
          eventEmitter.emit(EventConstants.GETQUALITYLEVEL, {
            qualityLevels: [
              ...qualityArray.sort((a, b) => b.bitrate - a.bitrate),
              { bitrate: -1, qualityLabel: 'Auto' },
            ],
          });
        } else {
          eventEmitter.emit(EventConstants.GETQUALITYLEVEL, {
            qualityLevels: [],
          });
        }
      });
    }
    return () => {
      if (hlsInstance) {
        hlsInstance.destroy();
      }
    };
  }, [data]);

  useEffect(() => {
    if (videoRef) {
      videoRef.current[0].addEventListener(
        'loadedmetadata',
        setTotalDurationOfMedia
      );
    }

    return () => {
      videoRef.current[0]?.removeEventListener(
        'loadedmetadata',
        setTotalDurationOfMedia
      );
    };
  }, [videoRef]);

  useEffect(() => {
    if (videoRef && hlsInstance) {
      if (selectedQuality.qualityLabel === 'Auto') {
        hlsInstance.currentLevel = -1; // Enable automatic level selection
      } else {
        const levelIndex = hlsInstance.levels.findIndex(
          (level) => level.bitrate === selectedQuality.bitrate
        );
        hlsInstance.currentLevel = levelIndex;
      }
    }
  }, [videoRef, hlsInstance, selectedQuality]);

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      // case 'Enter':
      case ' ':
        togglePlayPause(videoRef, audioRef, setIsPlaying);
        break;
      case 'ArrowRight':
        forwardVideoE({ interval: 10 });
        break;
      case 'ArrowLeft':
        rewindVideoE({ interval: 10 });
        break;
      // case 'f':
      //   toggleFullScreen();
      //   break;
      // case 'm':
      //   toggleMuteE({ wasMuted: videoRef.current[0].muted });
      //   break;
      case 'ArrowUp':
        adjustVolumeE({
          volume: Math.min(1, (videoRef.current[0].volume || 0) + 0.1),
        }); // Increase volume
        break;
      case 'ArrowDown':
        adjustVolumeE({
          volume: Math.max(0, (videoRef.current[0].volume || 0) - 0.1),
        }); // Decrease volume
        break;
      default:
        break;
    }
  };

  const initializeAirPlay = () => {
    const video = videoRef.current[0] as any; // Type assertion to avoid TypeScript errors
    console.log(video)
    console.log(video.webkitShowPlaybackTargetPicker)
    console.log("new imple",data[0].meta_value)
    const currentTime = videoRef.current[0].currentTime; // Get current playback time

    if (video && video.webkitShowPlaybackTargetPicker) {
      try {
        video.webkitShowPlaybackTargetPicker();
      } catch (error) {
        console.error('AirPlay not available:', error);
      }

      console.log('AirPlay is supported on this device');
    }
    else {
      console.log('AirPlay is not supported on this device');
      try {
        if (castInstance.available) {
          if (!castInstance.connected && videoRef.current) {
          castInstance.cast(data[0].meta_value);
          castInstance.on('playing', () => {
            console.log("new seek",data[0].meta_value,currentTime)

            castInstance.seek(currentTime);
          }); 
          videoRef.current[0].pause(); // Pause on the master device
        }
        }     
       } catch (error) {
        console.error('cast not available:', error);
      }

    }
  }

  useEffect(() => {
    // Listen for Chromecast time updates
    castInstance.on('timeupdate', () => {
      const currentcastTime = castInstance.time; // Current time on Chromecast
      const bufferedLength = videoRef.current[0].buffered.length || 0;
      const bufferedStart = bufferedLength ? videoRef.current[0].buffered.start(0) : 0;
      const bufferedEnd = bufferedLength
        ? videoRef.current[0].buffered.end(bufferedLength - 1)
        : 0;

      // Set event data in required format
      const eventData = {
        min: bufferedStart,
        max: bufferedEnd,
        value: currentcastTime,
      };
console.log(eventData);
      eventEmitter.emit(EventConstants.HANDLEPROGRESS, eventData);
    });
    const video = videoRef.current[0] as any; // Type assertion to avoid TypeScript errors

    video.addEventListener('play', () => {
      if (castInstance.connected) {
        videoRef.current[0].pause(); // Pause on the  device
      }    
    });
  }, []);


  useEffect(() => {
    if (mediaAttached && videoRef.current[0]) {
      eventEmitter.on(EventConstants.TOGGLEPLAYPAUSE, togglePlay);
      eventEmitter.on(EventConstants.FORWARD, forwardVideoE);
      eventEmitter.on(EventConstants.REWIND, rewindVideoE);
      eventEmitter.on(EventConstants.SEEKING, onSeekE);
      eventEmitter.on(EventConstants.TOGGLEMUTE, toggleMuteE);
      eventEmitter.on(EventConstants.ADJUSTVOLUME, adjustVolumeE);
      eventEmitter.on(EventConstants.CHANGEQUALITY, changeQuality);
      eventEmitter.on(EventConstants.AIRPLAY, initializeAirPlay);

      // document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (mediaAttached && videoRef.current[0]) {
        eventEmitter.off(EventConstants.TOGGLEPLAYPAUSE, togglePlay);
        eventEmitter.off(EventConstants.FORWARD, forwardVideoE);
        eventEmitter.off(EventConstants.REWIND, rewindVideoE);
        eventEmitter.off(EventConstants.SEEKING, onSeekE);
        eventEmitter.off(EventConstants.TOGGLEMUTE, toggleMuteE);
        eventEmitter.off(EventConstants.ADJUSTVOLUME, adjustVolumeE);
        eventEmitter.off(EventConstants.AIRPLAY, initializeAirPlay);
        // document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [mediaAttached, totalDuration]);

  useEffect(() => {
    let timeOutId = setTimeout(() => {
      setShowMediaOverlay(false);
    }, 1000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  const handlePlayPause = () => {
    const wasMuted = true;
    togglePlayPause(videoRef, audioRef, setIsPlaying);
    toggleMute(videoRef, audioRef, setIsMuted, wasMuted);
    setShowPlayPause(false);
  };

  return (
    <div
      // className={`soloVideoViewContainer ${isFullScreen ? 'fullScreen' : ''}`}
      className={`video-container ${isFullScreen ? 'fullScreen' : ''}`}
    >
      {showPlayPause &&
        <ul>
          <li className='play-pause-player' onClick={handlePlayPause}>
            <Play className="player-icon" />
          </li>
        </ul>
      }
      <video
        ref={(ref) => {
          if (ref) {
            videoRef.current[0] = ref as HTMLVideoElement;
          }
        }}
        controls={false}
        controlsList="nofullscreen nopictureinpicture"
        autoPlay={false}
        muted
        // className="videoContainer"
        onTimeUpdate={handleSoloProgress}
        onMouseEnter={() => {
          setShowMediaOverlay(true);
        }}
        onMouseLeave={() => {
          setShowMediaOverlay(false);
        }}
      ></video>
      {/* Overlay */}
      {/* Media Details */}
      {isFullScreen ? (
        // Fullscreen mode
        showControls && (
          <div className="mediaDetailOverlay fullscreen">
            <div className="topLine"></div>
            {/* <span className="videoTitle">{data[0].meta_key}</span>
            <img src="/images/icons/Icons-28.png" className="overlayLogo" /> */}
          </div>
        )
      ) : (
        // Normal mode
        <div
          className="SolomediaDetailOverlay"
          style={{ display:  'none' }}
        >
          <div className="topLine"></div>
          <span className="videoTitle">{data[0].meta_key}</span>
          <img src="/images/icons/Icons-28.png" className="overlayLogo" />
        </div>
      )}
    </div>
  );
};

export default SoloVideoView;
