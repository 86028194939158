import React, { useState } from 'react';
import { FormatDuration } from '../../utils/FormatDuration';
import eventEmitter from '../../utils/VoltaEventEmitter';
import { ReactComponent as VolumeOff } from '../../../../assets/icons/volume_off.svg';
import { ReactComponent as VolumeOn } from '../../../../assets/icons/Icons-01.svg';
import { ReactComponent as Rewind } from '../../../../assets/icons/ten-back-icon.svg';
import { ReactComponent as Pause  } from '../../../../assets/icons/play-forge-icon.svg';
import { ReactComponent as Play } from '../../../../assets/icons/pause-forge-icon.svg';
import { ReactComponent as Forward } from '../../../../assets/icons/ten-sec-for-icon.svg';
import { INTERVAL } from '../../constants';
interface BottomLeftProps {
  isStreamLive: boolean;
  totalDuration: number;
  currentTime: number;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isMuted: boolean;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
}
const BottomLeft: React.FC<BottomLeftProps> = ({
  isStreamLive,
  totalDuration,
  currentTime,
  isPlaying,
  isMuted,
  volume,
  setVolume,
}) => {
  // States
  const [volumeStyle, setVolumeStyle] = useState<{ display: string }>({
    display: 'none',
  });

  const togglePlay = () => {
    let PlayingStatus = isPlaying;
    const eventData = {
      PlayingStatus,
    };
    eventEmitter.emit('togglePlayPause', eventData);
  };

  const forwardVideo = () => {
    eventEmitter.emit('forward', {
      interval: INTERVAL,
    });
  };

  const rewindVideo = () => {
    eventEmitter.emit('rewind', {
      interval: INTERVAL,
    });
  };

  const toggleMute = () => {
    let wasMuted = isMuted;
    if (!isMuted) {
      setVolume(0);
      eventEmitter.emit('adjustVolume', {
        volume: 0,
      });
    } else {
      setVolume(1);
      eventEmitter.emit('adjustVolume', {
        volume: 0.1,
      });
    }
    const eventData = {
      wasMuted,
    };
    eventEmitter.emit('toggleMute', eventData);
  };

  const adjustVolumeE = (event: any) => {
    setVolume(event.target.value);
    eventEmitter.emit('adjustVolume', {
      volume: event.target.value,
    });
  };

  return (
    <ul className="bottomLeft">
      {isStreamLive ? (
        <li className="player-duration">LIVE</li>
      ) : (
        <li className="player-duration">{`${FormatDuration(currentTime)} ${
          totalDuration ? `/ ${FormatDuration(totalDuration)}` : ''
        } `}</li>
      )}
      <li
        className="player-volume"
        onMouseEnter={() => {
          setVolumeStyle({ display: 'inline-block' });
        }}
        onMouseLeave={() => {
          setVolumeStyle({ display: 'none' });
        }}
      >
        {isMuted ? (
          <VolumeOff className="player-icon" onClick={toggleMute} />
        ) : (
          // <img
          //   alt="mute"
          //   src="/images/icons/volume_off.svg"
          //   style={{ width: '27.74px', height: '27.1px' }}
          //   onClick={toggleMute}
          // ></img>
          <VolumeOn className="player-icon" onClick={toggleMute} />
          // <img
          //   alt="volume"
          //   src="/images/icons/Icons-01.svg"
          //   style={{ width: '27.74px', height: '27.1px' }}
          //   onClick={toggleMute}
          // ></img>
        )}
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          defaultValue="0.7"
          value={volume}
          onChange={adjustVolumeE}
          className="volume-slider"
          style={{ ...volumeStyle, position: 'relative', top: '-5px' }}
        />
      </li>
      {/* <li>
        <img
          alt="skip_next"
          src="/images/icons/Icons-02.png"
          className="horiMenuImg"
        ></img>
      </li> */}

      {/* <li className="player-rewind">
        <Rewind className="player-icon" onClick={rewindVideo} />
        <img
          alt="reload"
          src="/images/icons/Icons-03.png"
          style={{ width: '29.64px', height: '29.98px' }}
          onClick={rewindVideo}
        ></img>
      </li> */}
      <li className="player-playpause">
        {isPlaying ? (
          <Pause className="player-icon" onClick={togglePlay} />
        ) : (
          // <img
          //   src="/images/icons/Icons-19.png"
          //   className="horiMenuImg"
          //   alt="pause"
          //   onClick={togglePlay}
          // ></img>
          <Play className="player-icon" onClick={togglePlay} />
          // <img
          //   src="/images/icons/Icons-20.png"
          //   alt="play"
          //   className="horiMenuImg"
          //   onClick={togglePlay}
          // ></img>
        )}
      </li>

      {/* <li className="player-forward">
        <Forward className="player-icon" onClick={forwardVideo} />
        <img
          alt="reload_clockwise"
          src="/images/icons/Icons-04.png"
          style={{ width: '29.64px', height: '29.98px' }}
          onClick={forwardVideo}
        ></img>
      </li> */}

      {/* <li>
        <img
          alt="skip"
          src="/images/icons/Icons-05.png"
          className="horiMenuImg"
        ></img>
      </li> */}
    </ul>
  );
};

export default BottomLeft;
