// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "src/types";

interface AuthState extends User {
	token: string,
	isGuestUser: boolean,
	navigationList: any
	sliderList: any,
	upcomingEventList: any;
	assetDetails: any;
	castList: any;
	userProfile: any;
	recodedEventList: any,
	liveEventChat: any,
	previousRoute : string,
	userInfo : any;
}

const initialState: AuthState = {
	token: '',
	isGuestUser: true,
	navigationList: [],
	sliderList: [],
	upcomingEventList: [],
	assetDetails: [],
	castList: [],
	userProfile: {},
	recodedEventList: [],
	liveEventChat: [],
	previousRoute: '',
	userInfo : {},
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setTokenInfo: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setGuestUser: (state, action: PayloadAction<boolean>) => {
			state.isGuestUser = action.payload;
		},
		setUserNavigation: (state, action: PayloadAction<object>) => {
			state.navigationList = action.payload;
		},
		setSliderList: (state, action: PayloadAction<object>) => {
			state.sliderList = action.payload;
		},
		setUpcomingEventList: (state, action: PayloadAction<object>) => {
			state.upcomingEventList = action.payload;
		},
		setAssetDetails: (state, action: PayloadAction<object>) => {
			state.assetDetails = [...state.assetDetails, action.payload];
		},
		setCastList: (state, action: PayloadAction<object>) => {
			state.castList = action.payload;
		},
		setUserProfile: (state, action: PayloadAction<object>) => {
			state.userProfile = action.payload;
		},
		setRecordedEventList: (state, action: PayloadAction<object>) => {
			state.recodedEventList = action.payload;
		},
		setLiveEventChat: (state, action: PayloadAction<{ key: string, message: string }>) => {
			const existingIndex = state.liveEventChat.findIndex((chat: any) => chat.key === action.payload.key);
			existingIndex !== -1
				?
				state.liveEventChat[existingIndex].message = action.payload.message
				:
				state.liveEventChat.push({ eventKey: action.payload.key, message: action.payload.message });
		},
		setPreviousRoute: (state, action: PayloadAction<string>) => {
			state.previousRoute = action.payload;
		},
		setUserInfo: (state, action: PayloadAction<object>) => {
			state.userInfo = action.payload;
		},
	},
});

export const { setTokenInfo, setGuestUser, setUserNavigation,
	setSliderList, setUpcomingEventList, setAssetDetails,
	setCastList, setUserProfile, setRecordedEventList, setLiveEventChat,
	setPreviousRoute, setUserInfo } = authSlice.actions;

export default authSlice.reducer;
