interface EventListeners {
  [eventName: string]: Array<(...args: any[]) => void>;
}

class VoltaEventEmitter {
  eventListeners: EventListeners;
  constructor() {
    this.eventListeners = {};
  }

  on(eventName: string, listener: (...args: any[]) => void) {
    if (!this.eventListeners[eventName]) {
      this.eventListeners[eventName] = [];
    }
    this.eventListeners[eventName].push(listener);
  }

  off(eventName: string, listener: (...args: any[]) => void) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName] = this.eventListeners[eventName].filter(
        (existingListener) => existingListener !== listener
      );
    }
  }

  emit(eventName: string, data: any) {
    if (this.eventListeners[eventName]) {
      this.eventListeners[eventName].forEach((listener) => listener(data));
    }
  }
}

const eventEmitter = new VoltaEventEmitter();

export default eventEmitter;
