import './loader.scss';
import VOLTA_Loader_Gif from "src/assets/VOLTA-buffering.gif";
const Loader = () => {
    return (
        <div className="loader-bg">
            <div className='loader'>
                <img src={VOLTA_Loader_Gif} alt='round-img'/>
            </div>
            
        </div>
    )
};

export default Loader;