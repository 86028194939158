import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDFpQ_hQGYwKrNgJyognrOtS7GyinYRYyU",
    authDomain: "volta-ott-66911.firebaseapp.com",
    projectId: "volta-ott-66911",
    storageBucket: "volta-ott-66911.appspot.com",
    messagingSenderId: "167121841121",
    appId: "1:167121841121:web:a70f7635ea328078cc00a0",
    measurementId: "G-JDL1E4THZQ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
