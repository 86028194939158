import { useEffect, useRef, useState } from 'react';
import { AppConstants, CONTENT_TYPE, STREAM_DATA } from '../constants';
import '../Player.scss';
import RightMenu from './RightMenu';
import BottomMenu from './BottomMenu';
import TopLeftMenu from './TopSection/TopLeftMenu';
import TopCenter from './TopSection/TopCenter';
import { loadAudio } from '../utils/LoadAudio';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from 'src/redux/reducer/authSlice';
import { getLoggedInUserInfo } from 'src/redux/service/auth';
import { RootState } from 'src/redux/store/store';
import { ReactComponent as BackIcon } from 'src/assets/icons/back-arrow.svg';

interface VideoPlayerProps {
  signal: string | null;
  assetData: any;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ signal, assetData }) => {
  // Variables
  let timeoutId: NodeJS.Timeout;
  let isMouseMoving = false;
  const INTERVAL = 10;
  const { assetDetails, userInfo } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();

  // UseRefs
  const VideoPlayerContainerRef = useRef<HTMLDivElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // States
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(1);
  const [gridValue, setGridValue] = useState<string>(
    AppConstants.PLAYER_MODE_SOLO_TITLE
  );
  const [totalDuration, setTotalDuration] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(true);
  const [showMediaOverlay, setShowMediaOverlay] = useState<boolean>(true);
  const [data, setData] = useState<STREAM_DATA[]>([
    { meta_key: '', meta_value: '' },
  ]);
  const [eventLabel, setEventLabel] = useState<string>('');
  const [liveVideoUrls, setLiveVideoUrls] = useState<STREAM_DATA[]>([
    { meta_key: '', meta_value: '' },
  ]);
  const [audioStream, setAudioStream] = useState<string>('');
  const [isMutliView, setIsMultiView] = useState<number>(-1);
  const [showLiveChat, setShowLiveChat] = useState<boolean>(false);

  const goBack = () => {
    window.history.back();
  };

  const toggleFullScreen = () => {
    if (VideoPlayerContainerRef.current) {
      if (document.fullscreenElement === null) {
        // user not in full screen mode
        if (VideoPlayerContainerRef.current.requestFullscreen) {
          VideoPlayerContainerRef.current.requestFullscreen();
        }
      } else {
        // user is in full screen mode
        if (document.fullscreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
      }
    }
  };

  const handleMouseMove = () => {
    if (!isMouseMoving) {
      setShowControls(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowControls(false);
      }, 2000);
    } else {
      isMouseMoving = true;
      timeoutId = setTimeout(() => {
        isMouseMoving = false;
      }, 2000);
    }
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setShowControls(true);
      clearTimeout(timeoutId);
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    if (VideoPlayerContainerRef.current) {
      if (isFullScreen) {
        VideoPlayerContainerRef?.current.addEventListener(
          'mousemove',
          handleMouseMove
        );
      }
      VideoPlayerContainerRef.current.addEventListener(
        'fullscreenchange',
        handleFullscreenChange
      );
    }

    return () => {
      if (VideoPlayerContainerRef.current) {
        VideoPlayerContainerRef?.current.removeEventListener(
          'mousemove',
          handleMouseMove
        );

        VideoPlayerContainerRef.current.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange
        );
      }
    };
  }, [VideoPlayerContainerRef.current, isFullScreen]);

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      const userData: any = getLoggedInUserInfo();
      dispatch(setUserInfo(userData));
    }
  }, []);

  useEffect(() => {
    setEventLabel(assetData.externalId);
    setIsMultiView(assetData.mutli_view);
    // If isMultiView === 0 then its A+V => Single View :: Here i'll have just one object in my playbackurl array which will be A+V .m3u8
    // and if isMultiView === 1 then its A/V =>  Multi View :: Here 0th index we have audio stream; 1st to 9th we have video streams so here we'll remove the first object and pass it in array as our data is array of objects.
    if (assetData.externalId) {
      // Live
      if (assetData.mutli_view) {
        setAudioStream(assetData?.Live_playbackUrls[0].meta_value);
        setData([assetData?.Live_playbackUrls.slice(1)[0]]);
        setLiveVideoUrls(assetData?.Live_playbackUrls.slice(1));
      } else {
        setAudioStream('');
        setData(assetData?.Live_playbackUrls);
        setLiveVideoUrls(assetData?.Live_playbackUrls);
      }
    } else {
      // VOD
      setData([
        {
          meta_key: assetData.label,
          meta_value: assetData?.Vod_playbackUrl,
        },
      ]);
      setAudioStream('');
    }
  }, [assetData]);

  useEffect(() => {
    if (audioStream && audioRef.current) {
      loadAudio(audioStream, audioRef);
    }
  }, [audioStream, audioRef, gridValue]);

  return (
    <>
      <div
        className={`videoPlayerContainer ${showLiveChat ? 'ChatON' : ''}`}
        ref={VideoPlayerContainerRef}
      >
        {/* Top section */}
        <div className="videoPlayerTop">
          {/* Left Menu */}
          {/* Left Menu */}
          {!isFullScreen && (
            <div className="leftMenuContainer">
              <BackIcon onClick={goBack} className="backIcon" />
            </div>
          )}

          {/* Centre -> Video player */}
          <div className="playerContainer">
            <TopCenter
              data={data}
              liveVideoUrls={liveVideoUrls}
              signal={signal}
              gridValue={gridValue}
              assetData={assetData}
              isFullScreen={isFullScreen}
              totalDuration={totalDuration}
              setTotalDuration={setTotalDuration}
              setIsPlaying={setIsPlaying}
              setIsMuted={setIsMuted}
              setShowMediaOverlay={setShowMediaOverlay}
              showControls={showControls}
              showMediaOverlay={showMediaOverlay}
              setData={setData}
              setGridValue={setGridValue}
              audioRef={audioRef}
              isPlaying={isPlaying}
            />
          </div>

          {/* Hidden Audio Stream */}
          {audioStream && isMutliView && (
            <audio
              ref={audioRef}
              autoPlay
              style={{ display: 'none' }}
              muted
            ></audio>
          )}

          {/* Right Menu */}
          {showControls && (
            <RightMenu
              isFullScreen={isFullScreen}
              eventLabel={eventLabel}
              showLiveChat={showLiveChat}
              setShowLiveChat={setShowLiveChat}
              signal={signal}
            />
          )}
        </div>

        {/* Bottom section */}
        {showControls && (
          <BottomMenu
            data={data}
            setData={setData}
            liveVideoUrls={liveVideoUrls}
            isFullScreen={isFullScreen}
            gridValue={gridValue}
            setGridValue={setGridValue}
            isStreamLive={
              assetData?.contentType.toLowerCase() === CONTENT_TYPE.LIVE
            }
            totalDuration={totalDuration}
            INTERVAL={INTERVAL}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            isMuted={isMuted}
            setIsMuted={setIsMuted}
            volume={volume}
            setVolume={setVolume}
            toggleFullScreen={toggleFullScreen}
            isMutliView={isMutliView}
            signal={signal}
            assetName={assetData.label}
          />
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
