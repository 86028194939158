import React from 'react';
import { useNavigate } from 'react-router-dom';

interface StaticPageProps {
  title: string;
  eventName: string;
  onClickNavigation: string;
  replaytext: string
}
const StaticPage: React.FC<StaticPageProps> = ({
  title,
  eventName,
  onClickNavigation,
  replaytext
}) => {
  const navigate = useNavigate();
  return (
    <div className="static_page_container">
      <div className="static_page_content">
        <span className="page_title">{title}</span>
        {/* <span className="page_title">WELCOME TO THE SHOW</span> */}
        <span className="asset_title">{eventName}</span>

        <span className="present_txt">PRESENTED BY</span>
        <img src="/images/Volta_logo_M_1Ct_wht@2x.png" className="logo"></img>

        {/* {onClickNavigation && (
          <button
            className="cta_btn"
            onClick={() => {
              navigate(onClickNavigation);
            }}
          >
            Shows
          </button>
        )} */}

        {/* <p className="enjoy_txt">
          Enjoy your experience?{' '}
          <a href="" className="letUs_txt">
            Let us know how it was.
          </a>
        </p> */}
        <div className="powerd-by">
        <span className="pb-text">POWERED BY</span>
        <img src="/images/dct-ateme.png" />
        </div>
        <span className="page_title" style={{ top:'20px' , bottom: '40px'      }}>{replaytext}</span>
      </div>
    </div>
  );
};

export default StaticPage;
