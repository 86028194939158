import { useEffect, useState } from 'react';
import { useFooterlinksByIdMutation } from 'src/redux/api/usersAPI'
import decodeJwtToken from 'src/utils/decode-jwt-token';
import Loader from '../loader/loader';
import { scrollToTop } from 'src/utils/srcoll-to-top';
import './policy.scss';

const Policy = () => {
    const path = window.location.pathname;
    const id = path.split('/');
    const [footerlinksById] = useFooterlinksByIdMutation();
    const intialAgreement: any = {};
    const [agreement, setAgreement] = useState(intialAgreement);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        getAgreementDetails();
    }, [path]);

    const getAgreementDetails = async () => {
        setShowLoader(true);
        const agreementDetails: any = await footerlinksById({ linkId: id[2] })
        const { data } = decodeJwtToken(agreementDetails?.data);
        setAgreement(data[0]);
        scrollToTop();
        setShowLoader(false);
    }

    return (
        !showLoader
            ?
            <div className="agreement-wrapper">
                <h1>
                    {agreement?.label}
                </h1>
                <div className="agrrment-description" dangerouslySetInnerHTML={{ __html: agreement?.description }} />
            </div>
            :
            <Loader />
    )
};

export default Policy;