
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from 'jsonwebtoken';

const decodeJwtToken = (token: any) => {
	if(!token) return {};
	const decodedToken = jwtDecode<JwtPayload>(token);
	return decodedToken;
};

export default decodeJwtToken