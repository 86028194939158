import { Route, Routes, useLocation } from 'react-router-dom';
import Header from 'src/components/header/header';
import RouteList from 'src/enums/routes';
import './layout.scss';
import Footer from 'src/components/footer/fotter';
import { useState } from 'react';
import Loader from 'src/components/loader/loader';

function Layout() {
  const location = useLocation();
  const showHeaderMenu = ['/sign-in', '/video', '/av-sync', '/dmb-gorge'];
  const showFooterMenu = ['/video', '/av-sync', '/dmb-gorge'];
  const [loading, setLoading] = useState(false);
  const showHeader = () => {
    return !showHeaderMenu.some((url) => location.pathname.startsWith(url));
  };

  const showFooter = () => {
    return !showFooterMenu.some((url) => location.pathname.startsWith(url));
  };

  const onLoaderHandle = (isLoading: boolean) => {
    setLoading(isLoading)
  };

  return (
    loading ? <Loader /> :
    <>
      {showHeader() && <Header onLoaderHandle={onLoaderHandle} />}
      <div className={`${showHeader() ? 'page-container' : ''}`}>
        <Routes>
          {RouteList?.map((path) => {
            return (
              <Route path="/" key={path.path}>
                {path.defaultRoute && (
                  <Route index element={<path.Component />} />
                )}
                <Route
                  key={path.path}
                  path={path.path}
                  element={
                    path.defaultRoute ? <path.Component /> : <path.Component />
                  }
                />
              </Route>
            );
          })}
        </Routes>
      </div>
      {showFooter() && <Footer />}
    </>
  );
}
export default Layout;
