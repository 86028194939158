import React, { useState, useEffect, useRef } from 'react';
import { usePubNub } from 'pubnub-react';
import './live-chat.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/store/store';
import { setLiveEventChat, setUserProfile } from 'src/redux/reducer/authSlice';
import { getAuthToken, getuseridToken } from 'src/redux/service/auth';
import decodeJwtToken from 'src/utils/decode-jwt-token';
import { useProfileMutation } from 'src/redux/api/usersAPI';


const LiveChat = ({ eventLabel }: any) => {
    const { userInfo, userProfile } = useSelector((state: RootState) => state.auth);
    // const decodedToken = decodeJwtToken(userInfo);
    const userId = getuseridToken();
    // const userId = userInfo?.userId;
    var email = '';

    try {
     email = userInfo?.email || (userProfile && userProfile.data && userProfile.data[0].emailId) ? userProfile.data[0].emailId : '';
    } catch(err){
        console.log(userProfile)
        console.log(err)
    }
    const pubnub = usePubNub();
    const dispatch = useDispatch();
    const [channels] = useState([eventLabel]);
    const { liveEventChat } = useSelector((state: RootState) => state.auth);
    const [message, setMessage] = useState('');
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const token = getAuthToken();
    const [profile] = useProfileMutation();
    
    const handleMessage = (event: any) => {
        let message = event.message;
        if (typeof message === 'string' || message.hasOwnProperty('text')) {
            if (liveEventChat || liveEventChat.hasOwnProperty('text')) {
                const key = eventLabel;
                dispatch(setLiveEventChat({ key, message }));
                setTimeout(scrollToBottom, 100);
            }
        }
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const sendMessage = (message: any) => {
        const atIndex = email?.indexOf('@');
        const displayname = atIndex !== -1 ? email?.substring(0, atIndex): '' 
        if (message) {
            pubnub.publish({
                channel: channels[0],
                message: {
                    text: message,
                    userId: userId,
                    userName: displayname
                }
            })
                .then(() => {
                    setMessage('');
                    setTimeout(scrollToBottom, 100);
                });
        }
    };

    useEffect(() => {
        const messageListener = { message: handleMessage };
        pubnub.addListener(messageListener);
        pubnub.subscribe({ channels });
        return () => {
            pubnub.removeListener(messageListener);
            pubnub.unsubscribeAll();
        };
    }, []);

    useEffect(() => { 
        if(typeof(userInfo) === 'string'){
            getProfile(token); 
        };
        
    }, [])

    const getProfile = async (token: any) => {
        const UserID = getuseridToken();
        const profileInfo: any = await profile({ UserID });
        const profileData = decodeJwtToken(profileInfo.data);
        dispatch(setUserProfile(profileData));
    };

    return (
        <div className='live-chat-container'>
            <div className='live-inner'>
                <div className='header-styles'>Chat</div>
                <div className='list-styles' ref={chatContainerRef}>
                    {liveEventChat?.length > 0 && liveEventChat?.map((chatMsg: any, index: any) => {
                        const { eventKey, message } = chatMsg || {};
                        const displayname = message?.userName;

                        return (
                            eventKey == eventLabel && (
                                <div key={`message-${index}`} className='message-styles'>
                                    <div className='chat-userimg'><span>{displayname?.toUpperCase()}</span></div>
                                    <div className='chat-replies'>
                                        <span className='chat-username'>{displayname?.toUpperCase()}</span>
                                        <span className='chat-message'>
                                            {message?.text}
                                        </span>
                                    </div>
                                </div>
                            )
                        );
                    })}
                </div>
                <div className='footer-styles'>
                    <input
                        type="text"
                        className='input-styles'
                        placeholder="Type your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                sendMessage(message);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default LiveChat;