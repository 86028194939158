import { useEffect } from "react";
import { ReactComponent as Logo } from "src/assets/V-logo-grey.svg";
import "./upcoming.scss";
import {
  useRecordedEventsMutation,
  useUpComingEventsMutation,
} from "src/redux/api/usersAPI";
import { useDispatch, useSelector } from "react-redux";
import decodeJwtToken from "src/utils/decode-jwt-token";
import {
  setRecordedEventList,
  setUpcomingEventList,
} from "src/redux/reducer/authSlice";
import { RootState } from "src/redux/store/store";
import { MONTHS } from "src/enums/months-weeks";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/loader/loader";
import { scrollToTop } from "src/utils/srcoll-to-top";

const Upcoming = () => {
  const { upcomingEventList } = useSelector((state: RootState) => state.auth);
  const { recodedEventList } = useSelector((state: RootState) => state.auth);

  const [upComingEvents] = useUpComingEventsMutation();
  const [recordedEvents] = useRecordedEventsMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
    getCarouselList();
    getRecordedEvents();
  }, []);

  const getCarouselList = async () => {
    const response: any = await upComingEvents({});
    const decodedToken = decodeJwtToken(response?.data?.toString());
    const { data } = decodedToken;
    dispatch(setUpcomingEventList(data));
  };

  const getRecordedEvents = async () => {
    const response: any = await recordedEvents({});
    const decodedToken = decodeJwtToken(response?.data?.toString());
    const { data } = decodedToken;
    dispatch(setRecordedEventList(data));
  };

  const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);
    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    return `${month} ${day}`;
  };

  const getEventType = (startDate: Date, endDate: Date) => {
    const currentDate = new Date();
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const type = startDate <= currentDate && endDate > currentDate;
    return type;
  };

  return (
    <>
      {upcomingEventList.length > 0 ? (
        <>
          <div className="upcoming-wrapper">
            <div className="upcoming-title">{upcomingEventList[0]?.title}</div>
            <div className="card-Potrate">
              {upcomingEventList[0]?.data.map((cardItem: any) => {
                return (
                  <div
                    key={cardItem?.path}
                    className="card"
                    onClick={() => navigate(`/event-details/${cardItem.path}`)}
                  >
                    <div className="main-card">
                      <div className="card-box">
                        <img src={cardItem?.verticalFilePath} alt="card-img" />
                        <div className="card-text">
                          {cardItem?.label ? cardItem.label : cardItem?.title}
                        </div>
                      </div>
                      <div className="v-logo">
                        <Logo />
                      </div>
                      <div className="card-info-title">{cardItem?.venue_description}</div>
                      <div className="card-details-wrapper">
                        {getEventType(
                          cardItem.airStartDate,
                          cardItem.airEndDate
                        ) ? (
                          <div className="live-tag">
                            LIVE <span></span>
                          </div>
                        ) : (
                          <div className="tag">NEW</div>
                        )}
                        <div className="card-info">
                          {formatDate(cardItem.airStartDate)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <hr />

          <div className="upcoming-wrapper">
            <div className="upcoming-title">{recodedEventList[0]?.title}</div>
            <div className="card-Potrate">
              {recodedEventList[0]?.data.map((cardItem: any) => {
                return (
                  <div
                    key={cardItem?.path}
                    className="card"
                    onClick={() => navigate(`/event-details/${cardItem.path}`)}
                  >
                    <div className="main-card">
                      <div className="card-box">
                        <img src={cardItem?.verticalFilePath} alt="card-img" />
                        <div className="card-text">
                          {cardItem?.label ? cardItem.label : cardItem?.title}
                        </div>
                      </div>
                      <div className="v-logo">
                        <Logo />
                      </div>
                      <div className="card-info-title">{cardItem?.venue_description}</div>
                      <div className="card-details-wrapper">
                        {getEventType(
                          cardItem.airStartDate,
                          cardItem.airEndDate
                        ) ? (
                          <div className="live-tag">
                            LIVE <span></span>
                          </div>
                        ) : (
                          <div className="tag">NEW</div>
                        )}
                        <div className="card-info">
                          {formatDate(cardItem.airStartDate)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="content-wrapper">
            <div className="content-title">
              More content is coming very soon.
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default Upcoming;
