import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from 'src/assets/V-logo.svg'
import './order-confirmation.scss';

const OrderConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlLink = window.location.pathname;
    const path = urlLink.split("/");

    useEffect(() => {
        const timer = setTimeout(() => {
           // navigate(`/event-details/${path[2]}`);
        navigate(`/home`);

        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate, dispatch]);

    return (
        <div className="order-confirmation-wrapper">
            <h1 className="confirm-title">THANK YOU FOR PURCHASING</h1>
            <div className="thank-you-desc">
                <div>Please check your email for your</div>
                <div>ticket and additional information.</div>
            </div>
            <div className="thank-you-logo">
                <img src={logo} alt="Volta" />
            </div>
            <div className="thank-you-see-you-soon-text">See you soon!</div>
        </div>
    )
};

export default OrderConfirmation;