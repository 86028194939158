import React from 'react';

const HomeBottomBanner = () => {
  return (
    <div className='home-banner-b'>
      <div className='banner-content'>
        {/* <div>A rewatch opportunity will be announced </div>
        <div>as soon as possible.</div>
        <br /> */}
        {/* <div style={{ textAlign: 'center' }}><span style={{ color: "#ff6ce2" }}>'Join the Crowd'</span> access to the show will be available<br/> at 8PM ET/ 7PM CT / 5PM PT</div> */}
        <div style={{ textAlign: 'center' }}>
          <span style={{ color: '#ff6ce2' }}>
            Thank you for all the love and support!
          </span>
        </div>
      </div>
    </div>
  );
};

export default HomeBottomBanner;
