export const getFormattedCountdown = (countDown: number): string => {
  let days: string = Math.floor(countDown / (1000 * 60 * 60 * 24)).toString();
  let hours: string = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  ).toString();
  let minutes: string = Math.floor(
    (countDown % (1000 * 60 * 60)) / (1000 * 60)
  ).toString();
  let seconds: string = Math.floor((countDown % (1000 * 60)) / 1000).toString();

  // Padding with zeros if needed
  days = days.padStart(2, '0');
  hours = hours.padStart(2, '0');
  minutes = minutes.padStart(2, '0');
  seconds = seconds.padStart(2, '0');

  // Create an array to hold the values to display
  const countdownArray = [];

  // Add days if not zero
  if (days !== '00') {
    countdownArray.push(days);
  }

  // Add hours if not zero
  if (hours !== '00') {
    countdownArray.push(hours);
  }

  // Add minutes if not zero
  if (minutes !== '00') {
    countdownArray.push(minutes);
  }

  // Add seconds if not zero
  if (seconds !== '00') {
    countdownArray.push(seconds);
  }

  return countdownArray.join(':');
};
