import Matthew from "src/assets/matthew-kalapuch.png";
import logo from "src/assets/volta-big.png";
import "./about.scss";
import { scrollToTop } from "src/utils/srcoll-to-top";

const About = () => {
  scrollToTop();
  return (
    <div className="about-wrapper">
      <div className="back-wrapper">
        <img className="bg-blue-img" src={Matthew} alt="bg-img" />
        <div className="about-logo">
          <img src={logo} alt="logo-icon" />
        </div>
      </div>
      <div className="about-heading">
        VOLTA is a technology company focused on<span>  </span>
        <span>improving the way you experience the things you love.</span>
      </div>
      <div className="about-desc-wrapper">
        <div className="about-info-wrapper">
          <p>
            <span>
              VOLTA is a pioneering technology company on a
              mission to revolutionize the way people experience their favorite
              events, sports, and entertainment. <span> </span>
            </span>
            With over 15 years of dedicated technology development, VOLTA has
            brought forth a cutting-edge multi-view streaming web platform that
            empowers viewers to immerse themselves in their passions like never
            before.
          </p>
          <p>
            {" "}
            At VOLTA, we understand that every individual has their unique
            perspective and preferences. That’s why our platform offers an
            unparalleled level of freedom, allowing viewers to choose any
            broadcast angle they desire at any given moment. Whether you’re a
            sports aficionado craving an up-close view of the action or a music
            lover yearning for a front-row seat at a concert, VOLTA enables you to
            curate your experience seamlessly.
          </p>
          <p>
            Our journey began long before our official establishment in 2020. For
            years, we’ve been tirelessly developing our groundbreaking technology
            and fostering relationships across various sports industries and
            entertainment sectors. These deep-rooted connections enable us to
            bring you exclusive access to some of the most exciting events and
            performances around the globe.
          </p>
          <p>
            {" "}
            At VOLTA, we're a community of passionate individuals dedicated to
            enhancing the way people engage with the things they love. Whether
            you're a sports fanatic, a music enthusiast, or a superfan of live
            events, VOLTA invites you to join us in this amazing experience of
            exploration and discovery.
          </p>
        </div>
      </div>
    </div>
  );
};
export default About;
