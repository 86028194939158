import StripeCheckout, { Token } from 'react-stripe-checkout';
import { PAYMENT_GATEWAY_PUBLIC_KEY,PAYMENT_GATEWAY_PUBLIC_STAGE_KEY } from 'src/config/config';
import { useCreateOrderMutation, useProfileMutation } from 'src/redux/api/usersAPI'
import decodeJwtToken from 'src/utils/decode-jwt-token';
import { useNavigate } from 'react-router-dom';
import './checkout-container.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "src/redux/store/store";
import { useEffect } from 'react';
import { setUserProfile } from 'src/redux/reducer/authSlice';
import { getAuthToken } from 'src/redux/service/auth';
import analytics from 'src/config/firebase-config';
import { logEvent } from "firebase/analytics";

function CheckoutContainer({ userOrderDetails, onCheckoutLoader }: any) {
  const { userProfile } = useSelector((state: RootState) => state.auth);
  const [profile] = useProfileMutation();
  const dispatch = useDispatch();
  const { path, subscriptionId, billingName, billingEmail, userId, rate } = userOrderDetails || {};
  const [createOrder] = useCreateOrderMutation();
  const navigate = useNavigate();
  const amount = 29.99*100 ;
  const onToken = async (token: Token) => {
    const orderDetails = {
      orderId: token.id,
      assetId: 1,
      userId,
      subscriptionId: 1,
      billingName: isUserInDom() && `${userProfile?.data[0]?.firstName} ${userProfile?.data[0]?.lastName}`,
      billingEmail: billingEmail || token?.email,
      amount,
      status: "Active",
      usageType: "Paid",
      isActive: 1,
      paymentMethod: "stripe"
    }
    checkoutLoaderHandler(true);
    const { data }: any = await createOrder({ orderDetails });
    const orderInfo = decodeJwtToken(data);
    handleResponse(orderInfo);
    checkoutLoaderHandler(false);
  };

  const handleResponse = (response: any) => {
    logEvent(analytics, 'Order initialized');
    if (response.success) {
      logEvent(analytics, `Order completed payment succsess`,
        {
          user_name: isUserInDom() && `${userProfile?.data[0]?.firstName} ${userProfile?.data[0]?.lastName}`,
          user_email: billingEmail,
          user_id: userId,
        }
      );
      navigate(`/order-confirmation/${path}`);
    } else {
      console.log(response.message);
      logEvent(analytics, 'Order payment failed');
    }
  };

  const checkoutLoaderHandler = (isLoding: boolean) => {
    onCheckoutLoader(isLoding);
  };

  useEffect(() => {
    if (!userProfile) {
      const token = getAuthToken();
      getProfile(token);
    }
  }, [])

  const getProfile = async (token: any) => {
    const { data } = decodeJwtToken(token);
    const { userId } = data;
    const profileInfo: any = await profile({ userId });
    const profileData = decodeJwtToken(profileInfo.data);
    dispatch(setUserProfile(profileData));
  }

  const isUserInDom = () => {
    return userProfile && userProfile.data && userProfile.data.length > 0;
  }

  return (
    <div className='btn-event-buy-ref'>
      <StripeCheckout
        stripeKey={PAYMENT_GATEWAY_PUBLIC_STAGE_KEY}
        token={onToken}
        label="Buy Ticket"
        amount={amount}
        name={isUserInDom() && `${userProfile?.data[0]?.firstName} ${userProfile?.data[0]?.lastName}`}
        email={isUserInDom() && `${userProfile?.data[0]?.emailId}`}
        currency={'USD'}
        zipCode={true}
      ></StripeCheckout>
    </div>
  );
}

export default CheckoutContainer;