import React, { useState, useEffect } from 'react';

interface CountdownProps {
    startDate: Date;
}

interface TimeLeft {
    hours: string;
    minutes: string;
    seconds: string;
}

const TimerCounter: React.FC<CountdownProps> = ({ startDate }) => {
    startDate = new Date(startDate);
    const calculateTimeLeft = (): any => {
        const difference = startDate.getTime() - Date.now();

        if (difference <= 0 || difference > 24 * 60 * 60 * 1000) {
            // If the difference is zero or negative, or more than 24 hours, return null
            return null;
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        };
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [startDate]);

    if (!timeLeft) {
        return null;
    }

    return (
        <div>
            <span>
                {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </span>
        </div>
    );
};


export default TimerCounter;