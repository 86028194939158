import React, { useEffect, useState } from 'react';
import { getFormattedCountdown } from '../../utils/getFormattedCountdown';

export const useCountdown = (targetDate: any): string => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getFormattedCountdown(countDown);
};
