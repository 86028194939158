import React, { useState } from 'react';
import SoloVideoView from '../SoloVideoView';
import {
  AppConstants,
  CONTENT_TYPE,
  PUBNUB_SIGNALS,
  STREAM_DATA,
} from '../../constants';
import StaticPage from '../StaticPage';
import MultiVideoView from '../MultiVideoView';
import WaitingRoom from '../WaitingRoom';
import CountdownTimer from 'src/utils/count';

interface TopCenterProps {
  data: STREAM_DATA[];
  liveVideoUrls: STREAM_DATA[];
  signal: string | null;
  gridValue: string;
  assetData: any;
  isFullScreen: boolean;
  totalDuration: number;
  setTotalDuration: React.Dispatch<React.SetStateAction<number>>;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMediaOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  showControls: boolean;
  showMediaOverlay: boolean;
  setData: React.Dispatch<React.SetStateAction<STREAM_DATA[]>>;
  setGridValue: React.Dispatch<React.SetStateAction<string>>;
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  isPlaying: boolean;
}

const TopCenter: React.FC<TopCenterProps> = ({
  data,
  liveVideoUrls,
  signal,
  gridValue,
  assetData,
  isFullScreen,
  totalDuration,
  setTotalDuration,
  setIsPlaying,
  setIsMuted,
  setShowMediaOverlay,
  showControls,
  showMediaOverlay,
  setData,
  setGridValue,
  audioRef,
  isPlaying,
}) => {
  const [timeLeftString, setTimeLeftString] = useState('');

  if (assetData?.contentType.toLowerCase() === CONTENT_TYPE.LIVE) {
    // Content is LIVE
    switch (signal) {
      case PUBNUB_SIGNALS.IDLE:
        return (
          <WaitingRoom
            eventName={assetData.label}
            eventPoster={assetData.vodOrLivePosterImageFilePath}
            startTime={assetData.airStartDate}
            endTime={assetData.airEndDate}
          />
        );
      case PUBNUB_SIGNALS.STAND_BY:
        return (
          <>
            <CountdownTimer setTimeLeftString={setTimeLeftString} />
            <StaticPage
              title="WELCOME TO THE SHOW"
              eventName={assetData.label}
              onClickNavigation=""
              replaytext={timeLeftString ? `SHOW STARTS IN ${timeLeftString}` : ''}
            />
          </>
        );
      case PUBNUB_SIGNALS.STREAM_START:
        // 2 Possibilities: 1. Single View, 2. Multi View
        return gridValue === AppConstants.PLAYER_MODE_SOLO_TITLE ? (
          <SoloVideoView
            data={data}
            isFullScreen={isFullScreen}
            isStreamLive={assetData?.contentType.toLowerCase() === CONTENT_TYPE.LIVE}
            totalDuration={totalDuration}
            setTotalDuration={setTotalDuration}
            setIsPlaying={setIsPlaying}
            setIsMuted={setIsMuted}
            setShowMediaOverlay={setShowMediaOverlay}
            audioRef={audioRef}
            showControls={showControls}
            showMediaOverlay={showMediaOverlay}
            isPlaying={isPlaying}
          />
        ) : (
          <MultiVideoView
            gridValue={gridValue}
            data={data}
            liveVideoUrls={liveVideoUrls}
            setData={setData}
            setGridValue={setGridValue}
            setIsPlaying={setIsPlaying}
            setIsMuted={setIsMuted}
            audioRef={audioRef}
            showControls={showControls}
            isFullScreen={isFullScreen}
          />
        );
      case PUBNUB_SIGNALS.STREAM_PAUSE:
        return (
          <StaticPage
            title="STREAM PAUSE"
            eventName={assetData.label}
            onClickNavigation="/upcoming-event"
            replaytext=""
          />
        );
      case PUBNUB_SIGNALS.STREAM_END:
        return (
          <StaticPage
            title="THANK YOU FOR WATCHING"
            eventName={assetData.label}
            onClickNavigation="/upcoming-event"
            replaytext=""
          />
        );
      case PUBNUB_SIGNALS.ON_DEMAND_READY:
        return (
          <SoloVideoView
            data={data}
            isFullScreen={isFullScreen}
            isStreamLive={assetData?.contentType.toLowerCase() === CONTENT_TYPE.LIVE}
            totalDuration={totalDuration}
            setTotalDuration={setTotalDuration}
            setIsPlaying={setIsPlaying}
            setIsMuted={setIsMuted}
            setShowMediaOverlay={setShowMediaOverlay}
            audioRef={null}
            showControls={showControls}
            showMediaOverlay={showMediaOverlay}
            isPlaying={isPlaying}
          />
        );
      default:
        // Some Technical error screen
        return (
          <StaticPage
            title={`OUR APOLOGIES, WE'RE EXPERIENCING TECHNICAL DIFFICULTIES.`}
            eventName={assetData.label}
            onClickNavigation="/upcoming-event"
            replaytext=""
          />
        );
    }
  } else {
    // VOD: Solo view, A+V manifest
    return (
      <SoloVideoView
        data={data}
        isFullScreen={isFullScreen}
        isStreamLive={assetData?.contentType.toLowerCase() === CONTENT_TYPE.LIVE}
        totalDuration={totalDuration}
        setTotalDuration={setTotalDuration}
        setIsPlaying={setIsPlaying}
        setIsMuted={setIsMuted}
        setShowMediaOverlay={setShowMediaOverlay}
        audioRef={null}
        showControls={showControls}
        showMediaOverlay={showMediaOverlay}
        isPlaying={isPlaying}
      />
    );
  }
};

export default TopCenter;
