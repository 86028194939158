import React from 'react';
import RightTop from './RightTop';
import RightBottom from './RightBottom';

interface RightMenuProps {
  isFullScreen: boolean;
  eventLabel: string | null;
  setShowLiveChat: React.Dispatch<React.SetStateAction<boolean>>;
  showLiveChat: boolean;
  signal: string | null;
}
const RightMenu: React.FC<RightMenuProps> = ({
  isFullScreen,
  eventLabel,
  setShowLiveChat,
  showLiveChat,
  signal,
}) => {
  return (
    <div className="rightMenuContainer">
      {/* <RightTop /> */}
      <RightBottom
        eventLabel={eventLabel}
        setShowLiveChat={setShowLiveChat}
        showLiveChat={showLiveChat}
        signal={signal}
      />
    </div>
  );
};

export default RightMenu;
