import { MONTHS, WEEKDAY } from 'src/enums/months-weeks'

const formatDate = (inputDate: string) => {
    if (!inputDate) {
        return ""; // Handle case when inputDate is undefined or null
    }

    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
        return ""; // Handle case when inputDate is an invalid date
    }

    const month = MONTHS[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const weekday = WEEKDAY[date.getDay()];
    // const hours = date.getHours();
    // const minutes = date.getMinutes();
    // const formattedMinutes = (minutes < 10 ? '0' : '') + minutes; 
    const monthAbbreviation = month.slice(0, 3);
    const formattedDate = `${month} ${day}, ${year} | ${weekday}`;
    // const formattedDate = `${monthAbbreviation} ${day}, ${year} | ${weekday} ${hours}:${formattedMinutes}`;
    return formattedDate;
};


export default formatDate