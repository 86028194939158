import { EventConstants } from '../constants';
import eventEmitter from './VoltaEventEmitter';

export const togglePlayPause = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  audioRef: React.MutableRefObject<HTMLAudioElement | null> | null,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      if (videoRef) {
        if (videoRef.paused) {
          videoRef.play();
          setIsPlaying(true);
        } else {
          videoRef.pause();
          setIsPlaying(false);
        }
      }
    });
  }

  if (audioRef && audioRef.current) {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }
};

export const forwardVideo = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  totalDuration: number,
  interval: number
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      videoRef.currentTime = Math.min(
        totalDuration,
        (videoRef.currentTime as number) + interval
      );
    });
  }
  // if (audioRef.current) {
  //   audioRef.current.currentTime = Math.min(
  //     totalDuration,
  //     audioRef.current.currentTime + interval
  //   );
  // }
};

export const rewindVideo = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  interval: number
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      videoRef.currentTime = Math.max(
        0,
        (videoRef.currentTime as number) - interval
      );
    });
  }
};

export const onSeek = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  seekingValue: number
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      videoRef.currentTime = seekingValue;
    });
  }
};

export const toggleMute = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  audioRef: React.MutableRefObject<HTMLAudioElement | null> | null,
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>,
  wasMuted: boolean
) => {
  const wasMutedDup = wasMuted;
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      videoRef.muted = !wasMuted;
      setIsMuted(!wasMuted);
    });
  }
  console.log('audioRef && audioRef.current:', audioRef && audioRef.current);
  if (audioRef && audioRef.current) {
    audioRef.current.muted = !wasMutedDup;
  }
};

export const adjustVolume = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>,
  audioRef: React.MutableRefObject<HTMLAudioElement | null> | null,
  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>,
  volume: string
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef) => {
      if (volume === '0') {
        videoRef.muted = true;
        setIsMuted(true);
      } else {
        videoRef.muted = false;
        setIsMuted(false);
      }
      videoRef.volume = parseFloat(volume);
    });
  }
  if (audioRef && audioRef.current) {
    if (volume === '0') {
      audioRef.current.muted = true;
    } else {
      audioRef.current.muted = false;
    }
    audioRef.current.volume = parseFloat(volume);
  }
};

export const handleProgress = (
  videoRefs: React.MutableRefObject<HTMLVideoElement[]>
) => {
  if (Array.isArray(videoRefs.current)) {
    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        const currentTime = videoRef.currentTime;
        const bufferedLength = videoRef.buffered.length;
        const bufferedStart = bufferedLength ? videoRef.buffered.start(0) : 0;
        const bufferedEnd = bufferedLength
          ? videoRef.buffered.end(bufferedLength - 1)
          : 0;

        const eventData = {
          min: bufferedStart,
          max: bufferedEnd,
          value: currentTime,
        };

        eventEmitter.emit(EventConstants.HANDLEPROGRESS, eventData);
      }
    });
  }
};
